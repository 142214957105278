import React from "react";
import "./Header.sass";

function Header(props) {
  const { className } = props;

  return (
    <div className={`header-1 ${className || ""}`}>
      <div className="flex-row-3">
      <a href="/"><img className="image-391-1" src="/img/image-391-1@2x.png" /></a>
        <div className="navbar-1 lato-medium-shadow-blue-18px">
          <div className="frame-36812-1">
            <div className="sub-headings-9 lato-medium-shadow-blue-18px">
              <a href="/polis-about"><span className="span-2 lato-medium-shadow-blue-18px">About</span></a>
            </div>
          </div>
          <div className="navbar-link-sub-headings-4">
            <a href="/polis-how-it-works"><span className="span-2 lato-medium-shadow-blue-18px">How It Works</span></a>
          </div>
          <div className="navbar-link-sub-headings-5">
            <a href="/polis-how-it-works"><span className="span-2 lato-medium-shadow-blue-18px">Educators</span></a>
          </div>
          <div className="navbar-link-sub-headings-6">
            <a href="/polis-faq"><span className="span-2 lato-medium-shadow-blue-18px">FAQ</span></a>
          </div>
          <div className="navbar-link-sub-headings-7">
            <a href="/polis-blog"><span className="span-2 lato-medium-shadow-blue-18px">Blog</span></a>
          </div>
          <div className="primary-btn-6">
            <div className="button-text-6 lato-bold-mirage-16px">
            <a href="/polis-contact"><span className="span-2 lato-bold-mirage-16px">Get in touch</span></a>
            </div>
          </div>
        </div>
      </div>
      <img className="line-478-1" src="/img/line-478-1@2x.png" />
    </div>
  );
}

export default Header;
