import React from "react";
import Frame35995 from "../Frame35995";
import Frame35996 from "../Frame35996";
import "./BlogV1Card6.sass";

function BlogV1Card6(props) {
  const { overlapGroup6, spanText, frame35995Props, frame35996Props } = props;

  return (
    <div className="blog-v1-card-6">
      <div className="overlap-group6" style={{ backgroundImage: `url(${overlapGroup6})` }}></div>
      <div className="group-38099">
        <Frame35995 className={frame35995Props.className}>{frame35995Props.children}</Frame35995>
        <div className="address-3 lato-bold-mirage-24px">
          <span className="lato-bold-mirage-24px">{spanText}</span>
        </div>
        <Frame35996 className={frame35996Props.className} />
      </div>
    </div>
  );
}

export default BlogV1Card6;
