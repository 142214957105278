import React from "react";
import "./Group37933.sass";

function Group37933(props) {
  const { spanText1, spanText2, className } = props;

  return (
    <div className={`group-37933 ${className || ""}`}>
      <div className="sub-headings-111 lato-bold-mirage-18px">
        <span className="span-35 lato-bold-mirage-18px">{spanText1}</span>
      </div>
      <div className="overlap-group-17">
        <div className="sub-headings-112 lato-medium-shadow-blue-18px">
          <span className="span-35 lato-medium-shadow-blue-18px">{spanText2}</span>
        </div>
      </div>
    </div>
  );
}

export default Group37933;
