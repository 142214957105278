import React from "react";
import Header from "../Header";
import PrimaryBTN3 from "../PrimaryBTN3";
import Group39358 from "../Group39358";
import Footer from "../Footer";
import "./PolisAbout.sass";

function PolisAbout(props) {
  const {
    ellipse115,
    rectangle4105,
    spanText1,
    spanText2,
    spanText3,
    maskGroup1,
    vector21,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    spanText9,
    group39544,
    maskGroup2,
    spanText10,
    spanText11,
    spanText12,
    line455,
    spanText13,
    rectangle4108,
    ellipse412,
    spanText14,
    spanText15,
    spanText16,
    spanText17,
    spanText18,
    maskGroup3,
    maskGroup4,
    headerProps,
    primaryBTN3Props,
    group39358Props,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="polis-about screen">
        <div className="overlap-group3-4">
          <div className="overlap-group2-6">
            <div className="rectangle-4097"></div>
            <div className="ellipse-116"></div>
            <img className="ellipse-115" src={ellipse115} />
            <div className="ellipse-114-1"></div>
            <div className="rectangle-1513-1"></div>
            <img className="rectangle-4105" src={rectangle4105} />
            <div className="sub-headings-95 lato-bold-white-54px">
              <span className="lato-bold-white-54px">{spanText1}</span>
            </div>
            <div className="sub-headings-96 literata-bold-shadow-blue-16px">
              <span className="literata-bold-shadow-blue-16px">{spanText2}</span>
            </div>
            <div className="sub-headings-97 lato-medium-white-18px">
              <span className="lato-medium-white-18px">{spanText3}</span>
            </div>
            <img className="mask-group-8" src={maskGroup1} />
          </div>
          <Header className={headerProps.className} />
          <img className="vector-21-1" src={vector21} />
        </div>
        <div className="our-name">
          <div className="our-name-1 literata-bold-gray-suit-16px">
            <span className="literata-bold-gray-suit-16px">{spanText4}</span>
          </div>
          <div className="polis-means-city-i lato-bold-mirage-38px">
            <span className="lato-bold-mirage-38px">{spanText5}</span>
          </div>
          <div className="sub-headings-98 lato-medium-shadow-blue-18px">
            <span className="lato-medium-shadow-blue-18px">{spanText6}</span>
          </div>
        </div>
        <div className="our-mission">
          <div className="group-39349">
            <div className="group-39343">
              <div className="sub-headings-94 literata-bold-gray-suit-16px">
                <span className="literata-bold-gray-suit-16px">{spanText7}</span>
              </div>
              <div className="sub-headings-99 lato-bold-mirage-38px">
                <span className="lato-bold-mirage-38px">{spanText8}</span>
              </div>
              <div className="sub-headings-100 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText9}</span>
              </div>
            </div>
            <PrimaryBTN3 />
          </div>
          <div className="group-39544" style={{ backgroundImage: `url(${group39544})` }}></div>
        </div>
        <div className="our-story">
          <img className="mask-group-9" src={maskGroup2} />
          <div className="group-39316">
            <div className="sub-headings-94 literata-bold-gray-suit-16px">
              <span className="literata-bold-gray-suit-16px">{spanText10}</span>
            </div>
            <div className="sub-headings-101 lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText11}</span>
            </div>
            <div className="sub-headings-102 lato-medium-shadow-blue-18px">
              <span className="lato-medium-shadow-blue-18px">{spanText12}</span>
            </div>
            <img className="line-455" src={line455} />
            <div className="sub-headings-103 lato-medium-shadow-blue-18px">
              <span className="lato-medium-shadow-blue-18px">{spanText13}</span>
            </div>
            <PrimaryBTN3 className={primaryBTN3Props.className} />
          </div>
        </div>
        <div className="meet-the-team">
          <div className="overlap-group1-8">
            <div className="group-39247">
              <div className="e-container-2">
                <img className="rectangle-4108-2" src={rectangle4108} />
                <img className="ellipse-412-1" src={ellipse412} />
              </div>
              <div className="e-container-3">
                <div className="ellipse-413-3"></div>
                <div className="rectangle-4114-2"></div>
                <div className="ellipse-419-1"></div>
              </div>
            </div>
            <Group39358
              group39329={group39358Props.group39329}
              group39330={group39358Props.group39330}
              group39331={group39358Props.group39331}
              className={group39358Props.className}
            />
            <div className="sub-headings-104 lato-bold-white-38px">
              <span className="lato-bold-white-38px">{spanText14}</span>
            </div>
            <div className="sub-headings-105 literata-bold-gray-suit-16px">
              <span className="literata-bold-gray-suit-16px">{spanText15}</span>
            </div>
          </div>
        </div>
        <div className="why-polis">
          <div className="flex-col-12">
            <div className="text">
              <div className="why-polis-1 lato-bold-mirage-54px">
                <span className="lato-bold-mirage-54px">{spanText16}</span>
              </div>
              <div className="sub-headings-106 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText17}</span>
              </div>
            </div>
            <div className="primary-btn-30">
              <div className="button-text-32 lato-bold-white-18px">
                <span className="lato-bold-white-18px">{spanText18}</span>
              </div>
            </div>
          </div>
          <div className="images">
            <img className="mask-group-10" src={maskGroup3} />
            <img className="mask-group-11" src={maskGroup4} />
          </div>
        </div>
        <Footer
          group39616={footerProps.group39616}
          className={footerProps.className}
          group751Props={footerProps.group751Props}
          group731Props={footerProps.group731Props}
          group752Props={footerProps.group752Props}
          group732Props={footerProps.group732Props}
        />
      </div>
    </div>
  );
}

export default PolisAbout;
