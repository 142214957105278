import "./App.sass";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Team from "./components/Team";
import PolisBlog from "./components/PolisBlog";
import BlogPost from "./components/BlogPost";
import PolisFAQ from "./components/PolisFAQ";
import PolisHowItWorks from "./components/PolisHowItWorks";
import PolisTeamMember from "./components/PolisTeamMember";
import PolisAbout from "./components/PolisAbout";
import PolisContact from "./components/PolisContact";
import PolisHome from "./components/PolisHome";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/team">
          <Team {...teamData} />
        </Route>
        <Route path="/polis-blog">
          <PolisBlog {...polisBlogData} />
        </Route>
        {/* <Route path="/:path(|blog-post)">
          <BlogPost {...blogPostData} />
        </Route> */}
        <Route path="/polis-faq">
          <PolisFAQ {...polisFAQData} />
        </Route>
        <Route path="/polis-how-it-works">
          <PolisHowItWorks {...polisHowItWorksData} />
        </Route>
        <Route path="/polis-team-member">
          <PolisTeamMember {...polisTeamMemberData} />
        </Route>
        <Route path="/polis-about">
          <PolisAbout {...polisAboutData} />
        </Route>
        <Route path="/polis-contact">
          <PolisContact {...polisContactData} />
        </Route>
        <Route path="/">
          <PolisHome {...polisHomeData} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
const group393581Data = {
    group39329: "/img/group-39323-1@2x.png",
    group39330: "/img/group-39324-1@2x.png",
    group39331: "/img/group-39325-1@2x.png",
};

const team2Data = {
    group39332: "/img/group-39323-2@2x.png",
    group39333: "/img/group-39324-2@2x.png",
    group39334: "/img/group-39325-2@2x.png",
    group39358Props: group393581Data,
};

const primaryBTN1Data = {
    children: "Browse Careers",
};

const group751Data = {
    src: "/img/vector-1@2x.png",
};

const group731Data = {
    src: "/img/vector-2@2x.png",
};

const group752Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77",
};

const group732Data = {
    src: "/img/vector-4@2x.png",
    className: "group-79",
};

const teamData = {
    image391: "/img/image-391-1@2x.png",
    spanText1: "About",
    spanText2: "How It Works",
    spanText3: "Educators",
    spanText4: "FAQ",
    spanText5: "Blog",
    spanText6: "Get in touch",
    line478: "/img/line-478-1@2x.png",
    rectangle4108: "/img/rectangle-4108-1@2x.png",
    ellipse412: "/img/ellipse-412-1@2x.png",
    spanText7: "Team",
    spanText8: "Lorem ipsum dolor sit amet consectetur adipiscing elit feugiat nun eget massa velit eros etiam nunc.",
    spanText9: "We're looking to add more people to our team, join us today!",
    spanText10: "Lorem ipsum dolor sit amet, consectetur adipiscing elitolem accumsan convallis",
    line443: "/img/line-443-1@2x.png",
    spanText11: "Pages",
    spanText12: "Home",
    spanText13: "About",
    spanText14: "How It Works",
    spanText15: "Educators",
    spanText16: "FAQ",
    spanText17: "Blog",
    spanText18: "Get in Touch",
    vector2: "/img/vector-3@2x.png",
    group39616: "/img/mask-group-1@2x.png",
    spanText19: "Subscribe to our newsletter",
    spanText20: "Full name",
    spanText21: "Subscribe",
    line444: "/img/line-444-1@2x.png",
    spanText22: "Copyright © Marketingly X | Designed by ",
    spanText23: "BRIX Templates",
    spanText24: " - Powered by ",
    spanText25: "Webflow",
    team2Props: team2Data,
    primaryBTNProps: primaryBTN1Data,
    group751Props: group751Data,
    group731Props: group731Data,
    group752Props: group752Data,
    group732Props: group732Data,
};

const frame359951Data = {
    children: "March 11, 2021",
};

const frame359952Data = {
    children: "March 11, 2021",
};

const frame359953Data = {
    children: "March 11, 2021",
    className: "frame-35995-1",
};

const frame359963Data = {
    className: "frame-35996-1",
};

const blogV1Card11Data = {
    overlapGroup1: "/img/mask-group-4@2x.png",
    spanText: "5 SEO factors to consider for your website to rank better",
    frame35995Props: frame359953Data,
    frame35996Props: frame359963Data,
};

const frame359954Data = {
    children: "March 18, 2022",
    className: "frame-35995-1",
};

const frame359964Data = {
    className: "frame-35996-2",
};

const blogV1Card4Data = {
    overlapGroup2: "/img/mask-group-5@2x.png",
    spanText: "6 social media apps you should pay attention to",
    frame35995Props: frame359954Data,
    frame35996Props: frame359964Data,
};

const frame359955Data = {
    children: "March 22, 2022",
    className: "frame-35995-2",
};

const frame359965Data = {
    className: "frame-35996-2",
};

const blogV1Card21Data = {
    overlapGroup3: "/img/mask-group-6@2x.png",
    spanText: "What is growth hacking and how to apply it to your startup",
    frame35995Props: frame359955Data,
    frame35996Props: frame359965Data,
};

const frame359956Data = {
    children: "March 16, 2022",
    className: "frame-35995-2",
};

const frame359966Data = {
    className: "frame-35996-2",
};

const blogV1Card5Data = {
    overlapGroup4: "/img/mask-group-7@2x.png",
    spanText: "ASO: How to rank your app in the mobile app stores",
    frame35995Props: frame359956Data,
    frame35996Props: frame359966Data,
};

const frame359957Data = {
    children: "March 20, 2022",
    className: "frame-35995-3",
};

const frame359967Data = {
    className: "frame-35996-4",
};

const blogV1Card31Data = {
    overlapGroup5: "/img/mask-group-8@2x.png",
    spanText: "3 tips to increase engagement on social media",
    frame35995Props: frame359957Data,
    frame35996Props: frame359967Data,
};

const frame359958Data = {
    children: "March 14, 2022",
    className: "frame-35995-3",
};

const frame359968Data = {
    className: "frame-35996-1",
};

const blogV1Card6Data = {
    overlapGroup6: "/img/mask-group-9@2x.png",
    spanText: "4 productivity apps for your marketing team",
    frame35995Props: frame359958Data,
    frame35996Props: frame359968Data,
};

const cTAData = {
    rectangle4032: "/img/rectangle-4032-1@2x.png",
    maskGroup: "/img/mask-group-10@2x.png",
    spanText1: "Subscribe to our newsletter",
    spanText2: "example@youremail.com",
    spanText3: "Download",
};

const group753Data = {
    src: "/img/vector-7@2x.png",
};

const group733Data = {
    src: "/img/vector-8@2x.png",
};

const group754Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-1",
};

const group734Data = {
    src: "/img/vector-10@2x.png",
    className: "group-79-1",
};

const footer1Data = {
    group39616: "/img/mask-group-11@2x.png",
    group751Props: group753Data,
    group731Props: group733Data,
    group752Props: group754Data,
    group732Props: group734Data,
};

const polisBlogData = {
    spanText1: "Blog Title Goes Here",
    spanText2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud.",
    maskGroup1: "/img/mask-group-2@2x.png",
    spanText3: "What Is Personal Education?",
    spanText4: "We need to figure out why attempts to personalize learning have often ultimately led to a de-personalized educational experience in practice.",
    maskGroup2: "/img/mask-group-3@2x.png",
    spanText5: "Why We Founded Polis",
    spanText6: "Witnessing the pandemic's catastrophic impact on universities convinced us that there is a need for a new approach to online learning.",
    spanText7: "Latest posts",
    spanText8: "Previous",
    spanText9: "Next",
    frame359951Props: frame359951Data,
    frame359952Props: frame359952Data,
    blogV1Card1Props: blogV1Card11Data,
    blogV1Card4Props: blogV1Card4Data,
    blogV1Card2Props: blogV1Card21Data,
    blogV1Card5Props: blogV1Card5Data,
    blogV1Card3Props: blogV1Card31Data,
    blogV1Card6Props: blogV1Card6Data,
    cTAProps: cTAData,
    footerProps: footer1Data,
};

const header2Data = {
    className: "header-2",
};

const frame359959Data = {
    children: "March 11, 2021",
    className: "frame-35995-5",
};

const frame359969Data = {
    className: "frame-35996-5",
};

const blogV1Card12Data = {
    overlapGroup1: "/img/mask-group-17@2x.png",
    spanText: "What is Personal Education?",
    className: "blog-v1-card-1-1",
    frame35995Props: frame359959Data,
    frame35996Props: frame359969Data,
};

const frame3599510Data = {
    children: "March 22, 2022",
    className: "frame-35995-6",
};

const frame3599610Data = {
    className: "frame-35996-6",
};

const blogV1Card22Data = {
    overlapGroup3: "/img/mask-group-18@2x.png",
    spanText: "Why We Founded Polis",
    className: "blog-v1-card-2-1",
    frame35995Props: frame3599510Data,
    frame35996Props: frame3599610Data,
};

const secondaryBTN1Data = {
    children: "Browse all posts",
};

const frame3599511Data = {
    children: "March 20, 2022",
    className: "frame-35995-7",
};

const frame3599611Data = {
    className: "frame-35996-7",
};

const blogV1Card32Data = {
    overlapGroup5: "/img/mask-group-19@2x.png",
    spanText: "Blog Post #3",
    className: "blog-v1-card-3-1",
    frame35995Props: frame3599511Data,
    frame35996Props: frame3599611Data,
};

const group755Data = {
    src: "/img/vector-1@2x.png",
};

const group735Data = {
    src: "/img/vector-18@2x.png",
};

const group756Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-2",
};

const group736Data = {
    src: "/img/vector-20@2x.png",
    className: "group-79-2",
};

const footer2Data = {
    group39616: "/img/mask-group-11@2x.png",
    className: "footer-2",
    group751Props: group755Data,
    group731Props: group735Data,
    group752Props: group756Data,
    group732Props: group736Data,
};

const blogPostData = {
    group: "/img/group-9@2x.png",
    spanText1: "Back to all posts",
    group3035: "/img/group-3035-1@2x.png",
    spanText2: "Education",
    spanText3: "What Is Personal Education?",
    spanText4: "We need to figure out why attempts to personalize learning have often ultimately led to a de-personalized educational experience in practice.",
    maskGroup1: "/img/mask-group-12@2x.png",
    spanText5: "March 11, 2021",
    blogImage7: "/img/mask-group-13@2x.png",
    spanText6: "Our goal is to provide personal education to students with the world’s best educators anytime, anywhere. Naturally, someone might ask — “Doesn't personal education already exist today?”",
    spanText7: "Though the phrase “personal education” in English has its origins in the 1800s, the concept has a much earlier, longer, and broader history across different cultures. Analogous concepts of personal education existed in ancient China like xiū yǎng (修养), in ancient Greece like paideia (παιδεία), or in Germany like Bildung.",
    spanText8: "More than just buzzwords?",
    spanText9: <React.Fragment>In recent years, though there have been various attempts to make education more personalized, these initiatives have run into considerable problems. The best known one in educational circles today is what’s called “personalized learning.” <br /><br />Personalized learning is a broad umbrella term for attempts to personalize learning to the needs and interests of students. Although researchers and practitioners lack consensus on the precise meaning of the term, the US Office of Educational Technology has defined personalized learning in their national 2017 plan as:</React.Fragment>,
    overlapGroup: "/img/mask-group-14@2x.png",
    spanText10: "“instruction in which the pace of learning and the instructional approach are optimized for the needs of each learner. Learning objectives, instructional approaches, and instructional content (and its sequencing) may all vary based on learner needs. In addition, learning activities are meaningful and relevant to learners, driven by their interests, and often self-initiated.”",
    spanText11: <React.Fragment><br />Such a broad definition underpins a host of initiatives and projects for implementing personalized learning in K-12 schools, as well as at colleges and universities. Within the world of online education, &quot;personalized learning&quot; has motivated the creation of numerous platforms (or PLPs) for engaging students in more personalized ways inside and outside of the classroom. Furthermore, the term &quot;personalized learning&quot; has emerged among for-profit educational companies as a means to rebrand their one-on-one tutoring services. </React.Fragment>,
    spanText12: "In short, personalized learning (and personalized learning platforms) have become buzzwords in education.",
    image1: "/img/image-1@2x.png",
    spanText13: "A Messy Reality",
    spanText15: "under-personalized teaching. ",
    spanText16: <React.Fragment><br />As Mahnaz Charania pointed out, personalized learning platforms have generated a loss of </React.Fragment>,
    spanText17: "personalized connections",
    spanText18: <React.Fragment> for students and educators alike.<br /><br /></React.Fragment>,
    spanText19: "So should we give up on trying to personalize education for students (and educators)? ",
    spanText20: <React.Fragment>ABSOLUTELY NOT!<br /></React.Fragment>,
    image2: "/img/image-1@2x.png",
    spanText22: "Personal",
    spanText23: "ized",
    spanText24: " Education",
    spanText26: "If you’d like to learn more about what we offer, please contact us or write to us at ",
    spanText27: "polis@polis.ac",
    spanText28: ".",
    line477: "/img/line-477-1@2x.png",
    maskGroup2: "/img/mask-group-15@2x.png",
    spanText29: "Michael Tworek",
    spanText30: "CEO",
    spanText31: "Lorem ipsum dolor sit amet consectetur adipiscing elit habitasse pretium class neque scelerisque.",
    vector2: "/img/vector-13@2x.png",
    vector3: "/img/vector-14@2x.png",
    vector4: "/img/vector-15@2x.png",
    spanText32: "Latest articles",
    headerProps: header2Data,
    blogV1Card1Props: blogV1Card12Data,
    blogV1Card2Props: blogV1Card22Data,
    secondaryBTNProps: secondaryBTN1Data,
    blogV1Card3Props: blogV1Card32Data,
    footerProps: footer2Data,
};

const group391221Data = {
    spanText1: "How does Polis work?",
};

const header3Data = {
    className: "header-3",
};

const group391222Data = {
    spanText1: "How big is your marketing team?",
    className: "group-39122-1",
};

const group391223Data = {
    spanText1: "How big is your marketing team?",
    className: "group-39122-2",
};

const group757Data = {
    src: "/img/vector-23@2x.png",
};

const group737Data = {
    src: "/img/vector-2@2x.png",
};

const group758Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-3",
};

const group738Data = {
    src: "/img/vector-26@2x.png",
    className: "group-79-3",
};

const footer3Data = {
    group39616: "/img/mask-group-1@2x.png",
    className: "footer-3",
    group751Props: group757Data,
    group731Props: group737Data,
    group752Props: group758Data,
    group732Props: group738Data,
};

const polisFAQData = {
    spanText1: "Have questions? We have answers.",
    spanText2: "Lorem ipsum dolor sit amet consectetur adipiscing elit feugiat nun eget massa velit eros etiam nunc luctus varius",
    spanText3: "Frequently Asked Questions",
    rectangle4114: "/img/rectangle-4114-1@2x.png",
    spanText4: "Have questions?We have answers.",
    spanText5: "Our Packages",
    spanText6: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolo",
    rectangle4377: "/img/rectangle-4377-1@2x.png",
    rectangle4378: "/img/rectangle-4378-1@2x.png",
    line4581: "/img/line-458-1@2x.png",
    spanText7: "How is Polis different?",
    group391161: "/img/group-39242-1@2x.png",
    line4591: "/img/line-458-1@2x.png",
    spanText8: "Why does Polis offer office hour sessions?",
    group392421: "/img/group-39242-1@2x.png",
    line4601: "/img/line-458-1@2x.png",
    spanText9: "I’ve encountered a technical issue. What do I do?",
    group391171: "/img/group-39117-1@2x.png",
    spanText10: "General Questions",
    overlapGroup1: "/img/group-39174-1@2x.png",
    line4582: "/img/line-458-1@2x.png",
    spanText11: "What's your typical cost for a campaign?",
    group391162: "/img/group-39242-1@2x.png",
    line4592: "/img/line-458-1@2x.png",
    spanText12: "What certifications does Marketing has?",
    group392422: "/img/group-39242-1@2x.png",
    line4602: "/img/line-458-1@2x.png",
    spanText13: "Can I get a quote for a custom package?",
    group391172: "/img/group-39117-1@2x.png",
    line4611: "/img/line-458-1@2x.png",
    spanText14: "What is the typical timeline?",
    group392461: "/img/group-39242-1@2x.png",
    line4621: "/img/line-458-1@2x.png",
    spanText15: "Do you guarantee marketing results?",
    group392441: "/img/group-39242-1@2x.png",
    spanText16: "For Educators",
    overlapGroup2: "/img/group-39255-1@2x.png",
    line4583: "/img/line-458-1@2x.png",
    spanText17: "What's your typical cost for a campaign?",
    group391163: "/img/group-39242-1@2x.png",
    line4593: "/img/line-458-1@2x.png",
    spanText18: "What certifications does Marketing has?",
    group392423: "/img/group-39242-1@2x.png",
    line4603: "/img/line-458-1@2x.png",
    spanText19: "Can I get a quote for a custom package?",
    group391173: "/img/group-39117-1@2x.png",
    line4612: "/img/line-458-1@2x.png",
    spanText20: "What is the typical timeline?",
    group392462: "/img/group-39242-1@2x.png",
    line4622: "/img/line-458-1@2x.png",
    spanText21: "Do you guarantee marketing results?",
    group392442: "/img/group-39242-1@2x.png",
    spanText22: "For Learners",
    group391221Props: group391221Data,
    headerProps: header3Data,
    group391222Props: group391222Data,
    group391223Props: group391223Data,
    footerProps: footer3Data,
};

const primaryBTN21Data = {
    children: "Donwload eBook",
};

const header4Data = {
    className: "header-4",
};

const group759Data = {
    src: "/img/vector-7@2x.png",
};

const group739Data = {
    src: "/img/vector-30@2x.png",
};

const group7510Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-4",
};

const group7310Data = {
    src: "/img/vector-4@2x.png",
    className: "group-79-4",
};

const footer4Data = {
    group39616: "/img/mask-group-1@2x.png",
    className: "footer-4",
    group751Props: group759Data,
    group731Props: group739Data,
    group752Props: group7510Data,
    group732Props: group7310Data,
};

const polisHowItWorksData = {
    spanText1: "HOW IT WORKS",
    spanText2: "The World’s Best Educators At Your Fingertips",
    spanText3: "Lorem ipsum dolor sit amet consectetur adipiscing elit eu ac pulvinar sed facilisis bibendum massa malesuada fermentum platea posuere in tempor nibh sit nec nunc.",
    group1: "/img/group-14@2x.png",
    group2: "/img/group-14@2x.png",
    group3: "/img/group-14@2x.png",
    spanText4: "Educator Matching",
    spanText5: "1:1 Virtual Office Hours",
    spanText6: "Community & Support",
    rectangle1514: "/img/rectangle-1514-1@2x.png",
    maskGroup1: "/img/mask-group-23@2x.png",
    navIndicator: "/img/nav-indicator-1@2x.png",
    spanText7: "Using Polis",
    spanText8: "Who can access our global community of the world’s best Educators?",
    group38126: "/img/group-38126-1@2x.png",
    maskGroup2: "/img/mask-group-21@2x.png",
    spanText9: "Individual Learners",
    spanText10: "We provide individual access to adult learners (18+ years) such as professionals, part-time students, lifelong learners, and anyone seeking to reconnect academically to pursue their educational goals.",
    spanText11: "Request Learner Access",
    spanText12: "Request a Demo",
    spanText13: "Higher Education Institutions",
    spanText14: "We provide universities, colleges, and institutions of higher learning with access to a roster of top educators who can provide a wide range of academic and career services to their students and staff.",
    maskGroup3: "/img/mask-group-22@2x.png",
    rectangle4051: "/img/rectangle-4051-1@2x.png",
    overlapGroup: "/img/group-38018@1x.png",
    spanText15: "Interview Preparation",
    spanText16: "Lorem ipsum dolor sit amet consectetur adipiscing elit faucibus elit fermentum adipiscing facilis",
    line4511: "/img/line-451-1@2x.png",
    overlapGroup1: "/img/group-38018@1x.png",
    spanText17: "Cover Letter Review",
    spanText18: "Lorem ipsum dolor sit amet consectetur adipiscing elit faucibus elit fermentum adipiscing facilis",
    line4512: "/img/line-451-1@2x.png",
    overlapGroup2: "/img/group-38018@1x.png",
    spanText19: "Application Review",
    spanText20: "Lorem ipsum dolor sit amet consectetur adipiscing elit faucibus elit fermentum adipiscing facilis",
    line4513: "/img/line-451-1@2x.png",
    overlapGroup3: "/img/group-38018@1x.png",
    spanText21: "Efficiency",
    spanText22: "Lorem ipsum dolor sit amet consectetur adipiscing elit faucibus elit fermentum adipiscing facilis",
    line4514: "/img/line-451-4@2x.png",
    spanText23: "How Can Our Community of Educators Help You?",
    ebook2: "/img/group-36656-1@2x.png",
    spanText24: "Personalizing Your Education Begins with World’s Best Educators At Your Fingertips",
    spanText25: "Having trouble with completing a MOOC or online program? Don’t know what your educational background can do for your career? Need mentoring from academics about going to graduate school? Our Polis educators can help you in online office hours.",
    spanText26: "Request Access",
    spanText27: "Book a Demo",
    primaryBTN2Props: primaryBTN21Data,
    headerProps: header4Data,
    footerProps: footer4Data,
};

const header5Data = {
    className: "header-5",
};

const frame3599512Data = {
    children: "March 11, 2021",
    className: "frame-35995-8",
};

const frame3599612Data = {
    className: "frame-35996-8",
};

const blogV1Card13Data = {
    overlapGroup1: "/img/mask-group-27@2x.png",
    spanText: "What is Personal Education?",
    className: "blog-v1-card-1-2",
    frame35995Props: frame3599512Data,
    frame35996Props: frame3599612Data,
};

const frame3599513Data = {
    children: "March 22, 2022",
    className: "frame-35995-9",
};

const frame3599613Data = {
    className: "frame-35996-9",
};

const blogV1Card23Data = {
    overlapGroup3: "/img/mask-group-18@2x.png",
    spanText: "Why We Founded Polis",
    className: "blog-v1-card-2-2",
    frame35995Props: frame3599513Data,
    frame35996Props: frame3599613Data,
};

const frame3599514Data = {
    children: "March 20, 2022",
    className: "frame-35995-10",
};

const frame3599614Data = {
    className: "frame-35996-10",
};

const blogV1Card33Data = {
    overlapGroup5: "/img/mask-group-19@2x.png",
    spanText: "Blog Post #3",
    className: "blog-v1-card-3-2",
    frame35995Props: frame3599514Data,
    frame35996Props: frame3599614Data,
};

const group7511Data = {
    src: "/img/vector-39@2x.png",
};

const group7311Data = {
    src: "/img/vector-2@2x.png",
};

const group7512Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-5",
};

const group7312Data = {
    src: "/img/vector-42@2x.png",
    className: "group-79-5",
};

const footer5Data = {
    group39616: "/img/mask-group-1@2x.png",
    className: "footer-5",
    group751Props: group7511Data,
    group731Props: group7311Data,
    group752Props: group7512Data,
    group732Props: group7312Data,
};

const polisTeamMemberData = {
    rectangle4108: "/img/rectangle-4108-2@2x.png",
    vector21: "/img/vector-21-2@2x.png",
    maskGroup: "/img/mask-group-25@2x.png",
    spanText1: "Michael Tworek",
    spanText2: "Chief Executive Officer",
    line448: "/img/line-448-1@2x.png",
    spanText3: "About Michael",
    spanText4: "Michael is CEO of Polis. He received his PhD in History from Harvard University, where he has taught for over 11 years. Michael’s business, consulting, and academic expertise has been recognized by the Boston Consulting Group and the Boston Area Association for Study Abroad.",
    vector2: "/img/vector-35@2x.png",
    vector3: "/img/vector-36@2x.png",
    vector4: "/img/vector-37@2x.png",
    spanText5: "Articles by Michael",
    spanText6: "Browse all articles",
    headerProps: header5Data,
    blogV1Card1Props: blogV1Card13Data,
    blogV1Card2Props: blogV1Card23Data,
    blogV1Card3Props: blogV1Card33Data,
    footerProps: footer5Data,
};

const header6Data = {
    className: "header-6",
};

const primaryBTN32Data = {
    className: "primary-btn-32",
};

const group393582Data = {
    group39329: "/img/group-39323-3@2x.png",
    group39330: "/img/group-39324-3@2x.png",
    group39331: "/img/group-39325-3@2x.png",
    className: "team-cards",
};

const group7513Data = {
    src: "/img/vector-1@2x.png",
};

const group7313Data = {
    src: "/img/vector-30@2x.png",
};

const group7514Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-6",
};

const group7314Data = {
    src: "/img/vector-48@2x.png",
    className: "group-79-6",
};

const footer6Data = {
    group39616: "/img/mask-group-1@2x.png",
    className: "footer-6",
    group751Props: group7513Data,
    group731Props: group7313Data,
    group752Props: group7514Data,
    group732Props: group7314Data,
};

const polisAboutData = {
    ellipse115: "/img/ellipse-115-1@2x.png",
    rectangle4105: "/img/rectangle-4105-1@2x.png",
    spanText1: "One-on-one learning is the oldest form of education.",
    spanText2: "About US",
    spanText3: "But new technologies have created opportunities to make this approach more accessible. Polis enables students to receive personalized advice from educators, receiving feedback and guidance as they navigate increasingly complex academic and professional spheres.",
    maskGroup1: "/img/mask-group-35@2x.png",
    vector21: "/img/vector-21-2@2x.png",
    spanText4: "OUR NAME",
    spanText5: <React.Fragment>Polis means “city” in Greek.<br />The word can also mean “a body of citizens.”</React.Fragment>,
    spanText6: "We understand Polis to be a community of people — students, educators, and experts — who engage in productive conversations that can advance their academic or career goals.",
    spanText7: "OUr mission",
    spanText8: "Polis fosters mutual development and growth for educators and learners",
    spanText9: "Our online personal education platform provides one-on-one interactions for learners by matching them with the world’s best educators. Learners can find personalized solutions to their academic and career questions.",
    group39544: "/img/mask-group-34@2x.png",
    maskGroup2: "/img/mask-group-33@2x.png",
    spanText10: "Our story",
    spanText11: "Who We Are",
    spanText12: "We are a team of academics and professionals based in Cambridge, Massachusetts who believe that online personal education can transform higher education.",
    line455: "/img/line-455-1@2x.png",
    spanText13: "We have assembled a roster of personal educators who are professors, lecturers, PhD students, advanced graduate students, and professionals with degrees from the world’s best universities.",
    rectangle4108: "/img/rectangle-4108-1@2x.png",
    ellipse412: "/img/ellipse-412-2@2x.png",
    spanText14: "Meet the Polis Team",
    spanText15: "OUR TEAM",
    spanText16: "Why Polis?",
    spanText17: <React.Fragment>As educational opportunities increasingly migrate to online platforms, educators and students often lose out on the sense of community and individual advising that education has offered to past generations. <br /><br />Modern research has reaffirmed what educators and philosophers in antiquity already knew — one-on-one personal learning improves educational outcomes dramatically.  Using history and technology, we seek to create an inclusive, diverse, and dynamic community of learning in the digital age.</React.Fragment>,
    spanText18: "Read Our Education Integrity Policy",
    maskGroup3: "/img/mask-group-31@2x.png",
    maskGroup4: "/img/mask-group-32@2x.png",
    headerProps: header6Data,
    primaryBTN3Props: primaryBTN32Data,
    group39358Props: group393582Data,
    footerProps: footer6Data,
};

const header7Data = {
    className: "header-7",
};

const group379331Data = {
    spanText1: "Name",
    spanText2: "Full name",
};

const group379332Data = {
    spanText1: "Phone",
    spanText2: "(414) 449 - 0525",
    className: "group-37941",
};

const group379311Data = {
    spanText1: "Email",
    spanText2: "Email address",
};

const group379312Data = {
    spanText1: "Company",
    spanText2: "Company name",
    className: "group-37930",
};

const group7515Data = {
    src: "/img/vector-55@2x.png",
};

const group7315Data = {
    src: "/img/vector-8@2x.png",
};

const group7516Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-7",
};

const group7316Data = {
    src: "/img/vector-10@2x.png",
    className: "group-79-7",
};

const footer7Data = {
    group39616: "/img/mask-group-11@2x.png",
    className: "footer-7",
    group751Props: group7515Data,
    group731Props: group7315Data,
    group752Props: group7516Data,
    group732Props: group7316Data,
};

const polisContactData = {
    spanText1: "Follow us!",
    spanText2: "CONTACT US",
    spanText3: "If you need support or assistance, please check out our Support page or write to us here. Our general response time is one business day.",
    spanText4: "Interested in working with us? Send us a message.",
    rectangle4032: "/img/rectangle-4032-2@2x.png",
    ellipse407: "/img/ellipse-407-2@2x.png",
    group38113: "/img/rectangle-14-1@2x.png",
    spanText5: "Service",
    spanText6: "Select Service",
    spanText7: "Budget",
    spanText8: "Budget",
    vector2: "/img/vector-51@2x.png",
    spanText9: "Describe your project...",
    rectangle15: "/img/rectangle-15-1@2x.png",
    overlapGroup: "/img/vector-53@2x.png",
    vector3: "/img/vector-54@2x.png",
    spanText10: "contact@marketing.com",
    headerProps: header7Data,
    group379331Props: group379331Data,
    group379332Props: group379332Data,
    group379311Props: group379311Data,
    group379312Props: group379312Data,
    footerProps: footer7Data,
};

const header8Data = {
    className: "header-8",
};

const avatarSophieMooreCircleSmall1Data = {
    avatarSophieMooreCircleSmall: "/img/rectangle-952-1@2x.png",
    maskGroup: "/img/mask-group-47@2x.png",
};

const avatarSophieMooreCircleSmall2Data = {
    maskGroup: "/img/bg-avatar-2@2x.png",
    avatarSophieMooreCircleSmall: "/img/mask-group-48@2x.png",
    className: "avatar-andy-smith-circle-small",
};

const primaryBTN42Data = {
    className: "primary-btn-34",
};

const secondaryBTN2Data = {
    children: "Browse services",
    className: "secondary-btn-2",
};

const group393091Data = {
    spanText1: "Book an one-on-one digital office hours session with a Personal Educator.",
    spanText2: "Book",
};

const group393092Data = {
    spanText1: "Once approved, search our community of Personal Educators to find what you need.",
    spanText2: "Search",
    className: "group-39308",
};

const group393093Data = {
    spanText1: "Meet with your Personal Educator in a one-on-one video conference to address your needs.",
    spanText2: "Meet",
    className: "group-393",
};

const group378532Data = {
    className: "group-37853-1",
};

const primaryBTN22Data = {
    children: "Download eBook",
    className: "primary-btn-28",
};

const primaryBTN5Data = {
    children: "Browse Articles",
    className: "primary-btn-3",
};

const frame3599515Data = {
    children: "March 11, 2021",
    className: "frame-35995-11",
};

const frame3599615Data = {
    className: "frame-35996-11",
};

const blogV1Card14Data = {
    overlapGroup1: "/img/mask-group-17@2x.png",
    spanText: "What is Personal Education?",
    className: "blog-v1-card-1-3",
    frame35995Props: frame3599515Data,
    frame35996Props: frame3599615Data,
};

const frame3599516Data = {
    children: "March 22, 2022",
    className: "frame-35995-12",
};

const frame3599616Data = {
    className: "frame-35996-12",
};

const blogV1Card24Data = {
    overlapGroup3: "/img/mask-group-18@2x.png",
    spanText: "Why We Founded Polis",
    className: "blog-v1-card-2-3",
    frame35995Props: frame3599516Data,
    frame35996Props: frame3599616Data,
};

const frame3599517Data = {
    children: "March 20, 2022",
    className: "frame-35995-4",
};

const frame3599617Data = {
    className: "frame-35996-3-1",
};

const blogV1Card34Data = {
    overlapGroup5: "/img/mask-group-19@2x.png",
    spanText: "Blog Post #3",
    className: "blog-v1-card-3-3",
    frame35995Props: frame3599517Data,
    frame35996Props: frame3599617Data,
};

const group378533Data = {
    className: "group-39132",
};

const frame3599518Data = {
    children: "March 20, 2022",
    className: "frame-35995-4",
};

const frame3599618Data = {
    className: "frame-35996-3",
};

const blogV1Card35Data = {
    overlapGroup5: "/img/mask-group-41@2x.png",
    spanText: "Blog Post #4",
    className: "blog-v1-card-4-1",
    frame35995Props: frame3599518Data,
    frame35996Props: frame3599618Data,
};

const group379333Data = {
    spanText1: "Name",
    spanText2: "Full name",
};

const group379334Data = {
    spanText1: "Phone",
    spanText2: "(414) 449 - 0525",
    className: "group-37941-1",
};

const group379313Data = {
    spanText1: "Email",
    spanText2: "Email address",
};

const group379314Data = {
    spanText1: "Company",
    spanText2: "Company name",
    className: "group-37930-1",
};

const group7517Data = {
    src: "/img/vector-1@2x.png",
};

const group7317Data = {
    src: "/img/vector-30@2x.png",
};

const group7518Data = {
    src: "/img/vector-5@2x.png",
    className: "group-77-8",
};

const group7318Data = {
    src: "/img/vector-4@2x.png",
    className: "group-79-8",
};

const footer8Data = {
    group39616: "/img/mask-group-1@2x.png",
    className: "footer-8",
    group751Props: group7517Data,
    group731Props: group7317Data,
    group752Props: group7518Data,
    group732Props: group7318Data,
};

const polisHomeData = {
    spanText1: "Key BENEFITS",
    spanText2: "The future of education for adult learners is personal.",
    spanText3: "Educator Matching",
    spanText4: "Our proprietary data-driven, human-informed matching system provides each learner with suggested educators based on what learners need.",
    line4511: "/img/line-451-7@2x.png",
    spanText5: "Where personal education takes place in higher ed. Learners meet with educators in curated 1:1 semi-structured sessions on our own secure virtual space.",
    spanText6: "1:1 Virtual Office Hours",
    line4512: "/img/line-451-6@2x.png",
    spanText7: "We provide continuous support, personalized suggestions, and resources to help our users achieve their goals in our learning community.",
    spanText8: "Community & Support",
    line4513: "/img/line-451-5@2x.png",
    ellipse114: "/img/ellipse-114-1@2x.png",
    rectangle1514: "/img/rectangle-1514-2@2x.png",
    vector15: "/img/vector-15-2@2x.png",
    vector16: "/img/vector-16-2@2x.png",
    group39549: "/img/group-39549-1@2x.png",
    avatarMikeWarrenCircleSmall: "/img/bg-avatar-1@2x.png",
    maskGroup1: "/img/mask-group-46@2x.png",
    spanText9: "Title Here",
    spanText10: "Personalized Education with the World’s Best Educators — Anytime, Anywhere",
    spanText11: "Polis connects adult learners with the world’s best professors and professionals to find personalized answers to their educational questions at key transitions in their lives.",
    spanText12: "Request Learner Access",
    spanText13: "About us",
    spanText14: "A new approach to one-on-one learning",
    spanText15: "Polis enables students to receive personalized advice from educators, receiving feedback and guidance as they navigate increasingly complex academic and professional spheres.",
    maskGroup2: "/img/mask-group-44@2x.png",
    maskGroup3: "/img/mask-group-45@2x.png",
    spanText16: "Learn More",
    spanText17: "How It Works",
    spanText18: "Personalizing Your Education Begins with World’s Best Educators At Your Fingertips",
    spanText19: "Sign-up or Log In",
    spanText20: "Sign up and create a profile page in our digital community.",
    spanText21: "Students",
    spanText22: "What Students are Saying About Us",
    maskGroup4: "/img/mask-group-49@2x.png",
    spanText23: "Kiyeon Lee",
    spanText24: "Student",
    spanText25: "“Through Polis, I strengthened my job interviewing skills and also had fascinating discussions with professors about great ideas in history and other insights.”",
    spanText26: <React.Fragment>“Over three sessions with Polis<br />educators, my graduate school<br />ambitions transformed from a rough<br />idea into a concrete set of plans to<br />tackle the admissions process.”</React.Fragment>,
    maskGroup5: "/img/mask-group-50@2x.png",
    spanText27: "Jonah Simon",
    spanText28: "Student",
    spanText29: <React.Fragment>“The educator through Polis helped<br />me answer questions I didn’t even<br />know I had and answered questions<br />even my institution had not<br />answered.”</React.Fragment>,
    maskGroup6: "/img/mask-group-51@2x.png",
    spanText30: "Jailene Ramos",
    spanText31: "Student",
    spanText32: "Lorem ipsum dolor sit amet consectetur adipiscing elit faucibus elit fermentum adipiscing facilisi eu massa habitant",
    spanText33: "EDUCATORS",
    spanText34: <React.Fragment>Transforming Higher Education<br />Begins With You</React.Fragment>,
    spanText35: "Join our global community of the world’s best educators who are creating impact with their expertise and experience for adult learners around the world.",
    spanText36: "Join Our Community",
    maskGroup7: "/img/mask-group-42@2x.png",
    spanText37: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius tempor incididunt ut labore et”",
    maskGroup8: "/img/mask-group-43@2x.png",
    spanText38: "John Carter",
    spanText39: "Educator at X University",
    spanText40: "Resources",
    spanText41: "Take a look at our latest articles & resources",
    spanText42: "Read FAQ",
    spanText43: "CONTACT US",
    spanText44: "If you need support or assistance, please check out our Support page or write to us here. Our general response time is one business day.",
    spanText45: "Interested in working with us? Send us a message.",
    overlapGroup3: "/img/rectangle-14-3@2x.png",
    group39141: "/img/group-39141-1@2x.png",
    spanText46: "Email us",
    spanText47: "contact@marketing.com",
    rectangle4032: "/img/rectangle-4032-2@2x.png",
    ellipse407: "/img/ellipse-407-2@2x.png",
    group38113: "/img/rectangle-14-1@2x.png",
    spanText48: "Service",
    spanText49: "Select Service",
    spanText50: "Budget",
    spanText51: "Budget",
    vector2: "/img/vector-51@2x.png",
    spanText52: "Describe your project...",
    headerProps: header8Data,
    avatarSophieMooreCircleSmall1Props: avatarSophieMooreCircleSmall1Data,
    avatarSophieMooreCircleSmall2Props: avatarSophieMooreCircleSmall2Data,
    primaryBTN4Props: primaryBTN42Data,
    secondaryBTNProps: secondaryBTN2Data,
    group393091Props: group393091Data,
    group393092Props: group393092Data,
    group393093Props: group393093Data,
    group378531Props: group378532Data,
    primaryBTN2Props: primaryBTN22Data,
    primaryBTNProps: primaryBTN5Data,
    blogV1Card1Props: blogV1Card14Data,
    blogV1Card2Props: blogV1Card24Data,
    blogV1Card31Props: blogV1Card34Data,
    group378532Props: group378533Data,
    blogV1Card32Props: blogV1Card35Data,
    group379331Props: group379333Data,
    group379332Props: group379334Data,
    group379311Props: group379313Data,
    group379312Props: group379314Data,
    footerProps: footer8Data,
};

