import React from "react";
import "./PrimaryBTN.sass";

function PrimaryBTN(props) {
  const { children, className } = props;

  return (
    <div className={`primary-btn-2 ${className || ""}`}>
      <div className="button-text-2 lato-bold-white-18px">
        <span className="spanwnigti lato-bold-white-18px">{children}</span>
      </div>
    </div>
  );
}

export default PrimaryBTN;
