import React from "react";
import "./Group39122.sass";

function Group39122(props) {
  const { spanText1, className } = props;

  return (
    <div className={`group-39122 ${className || ""}`}>
      <div className="group-2068">
        <div className="heading-h2-2 lato-bold-mirage-22px">
          <span className="span-30 lato-bold-mirage-22px">{spanText1}</span>
        </div>
        <p className="paragraph lato-normal-shadow-blue-16px">
          <span className="span-30 lato-normal-shadow-blue-16px">
            Lorem ipsum dolor sit amet, consecteturor adipiscing elit. Tincidunt donec vulputate ipsum erat urna auctor
            get phasellus ideirs lorem ipsum dolor sit amet, consecteturor adipiscing elit
          </span>
        </p>
      </div>
      <img className="group-39115" src="/img/group-39115-1@2x.png" />
    </div>
  );
}

export default Group39122;
