import React from "react";
import "./Group37931.sass";

function Group37931(props) {
  const { spanText1, spanText2, className } = props;

  return (
    <div className={`group-37931 ${className || ""}`}>
      <div className="sub-headings-115 lato-bold-mirage-18px">
        <span className="span-37 lato-bold-mirage-18px">{spanText1}</span>
      </div>
      <div className="overlap-group-19">
        <div className="sub-headings-116 lato-medium-shadow-blue-18px">
          <span className="span-37 lato-medium-shadow-blue-18px">{spanText2}</span>
        </div>
      </div>
    </div>
  );
}

export default Group37931;
