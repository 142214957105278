import React from "react";
import "./Group39309.sass";

function Group39309(props) {
  const { spanText1, spanText2, className } = props;

  return (
    <div className={`group-393-1 ${className || ""}`}>
      <div className="group-38028-7"></div>
      <div className="sub-headings-container-9">
        <div className="sub-headings-153 lato-medium-shadow-blue-18px">
          <span className="span-41 lato-medium-shadow-blue-18px">{spanText1}</span>
        </div>
        <div className="sub-headings-154 lato-bold-mirage-24px">
          <span className="span-41 lato-bold-mirage-24px">{spanText2}</span>
        </div>
      </div>
    </div>
  );
}

export default Group39309;
