import React from "react";
import "./Group39358.sass";

function Group39358(props) {
  const { group39329, group39330, group39331, className } = props;

  return (
    <div className={`group-39358 ${className || ""}`}>
      <div className="group-39329" style={{ backgroundImage: `url(${group39329})` }}></div>
      <div className="group-39330" style={{ backgroundImage: `url(${group39330})` }}></div>
      <div className="group-39331" style={{ backgroundImage: `url(${group39331})` }}></div>
    </div>
  );
}

export default Group39358;
