import React from "react";
import Frame35995 from "../Frame35995";
import Frame35996 from "../Frame35996";
import "./BlogV1Card4.sass";

function BlogV1Card4(props) {
  const { overlapGroup2, spanText, frame35995Props, frame35996Props } = props;

  return (
    <div className="blog-v1-card-4">
      <div className="overlap-group2-1" style={{ backgroundImage: `url(${overlapGroup2})` }}></div>
      <div className="group-38097">
        <Frame35995 className={frame35995Props.className}>{frame35995Props.children}</Frame35995>
        <div className="address-1 lato-bold-mirage-24px">
          <span className="lato-bold-mirage-24px">{spanText}</span>
        </div>
        <Frame35996 className={frame35996Props.className} />
      </div>
    </div>
  );
}

export default BlogV1Card4;
