import React from "react";
import "./CTA.sass";

function CTA(props) {
  const { rectangle4032, maskGroup, spanText1, spanText2, spanText3 } = props;

  return (
    <div className="cta">
      <div className="overlap-group7">
        <img className="rectangle-4032" src={rectangle4032} />
        <img className="mask-group-1" src={maskGroup} />
        <div className="group-38107">
          <div className="subscribe-to-our-newsletter-1 lato-bold-white-38px">
            <span className="lato-bold-white-38px">{spanText1}</span>
          </div>
          <div className="overlap-group-4">
            <div className="sub-headings-25 lato-medium-shadow-blue-18px">
              <span className="lato-medium-shadow-blue-18px">{spanText2}</span>
            </div>
            <div className="primary-btn-14">
              <div className="button-text-14 lato-bold-white-18px">
                <span className="lato-bold-white-18px">{spanText3}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTA;
