import React from "react";
import Header from "../Header";
import BlogV1Card1 from "../BlogV1Card1";
import BlogV1Card2 from "../BlogV1Card2";
import SecondaryBTN from "../SecondaryBTN";
import BlogV1Card3 from "../BlogV1Card3";
import Footer from "../Footer";
import "./BlogPost.sass";

function BlogPost(props) {
  const {
    group,
    spanText1,
    group3035,
    spanText2,
    spanText3,
    spanText4,
    maskGroup1,
    spanText5,
    blogImage7,
    spanText6,
    spanText7,
    spanText8,
    spanText9,
    overlapGroup,
    spanText10,
    spanText11,
    spanText12,
    image1,
    spanText13,
    spanText15,
    spanText16,
    spanText17,
    spanText18,
    spanText19,
    spanText20,
    image2,
    spanText22,
    spanText23,
    spanText24,
    spanText26,
    spanText27,
    spanText28,
    line477,
    maskGroup2,
    spanText29,
    spanText30,
    spanText31,
    vector2,
    vector3,
    vector4,
    spanText32,
    headerProps,
    blogV1Card1Props,
    blogV1Card2Props,
    secondaryBTNProps,
    blogV1Card3Props,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="blog-post screen">
        <Header className={headerProps.className} />
        <div className="frame-35996-14">
          <img className="group-1" src={group} />
          <div className="sub-headings-66 lato-medium-mirage-18px">
            <span className="lato-medium-mirage-18px">{spanText1}</span>
          </div>
        </div>
        <div className="secondary-btn">
          <img className="icon-search" src={group3035} />
          <div className="button-text-23 lato-bold-mirage-18px">
            <span className="lato-bold-mirage-18px">{spanText2}</span>
          </div>
        </div>
        <div className="what-is-personal-education-1 lato-bold-mirage-54px">
          <span className="lato-bold-mirage-54px">{spanText3}</span>
        </div>
        <div className="we-need-to-figure-ou lato-medium-shadow-blue-18px">
          <span className="lato-medium-shadow-blue-18px">{spanText4}</span>
        </div>
        <div className="flex-row-19">
          <img className="mask-group-2" src={maskGroup1} />
          <div className="frame-35996-15">
            <div className="sub-headings-67 lato-medium-mirage-18px">
              <span className="lato-medium-mirage-18px">{spanText5}</span>
            </div>
          </div>
        </div>
        <div className="blog-image-7" style={{ backgroundImage: `url(${blogImage7})` }}></div>
        <div className="group-7127">
          <div className="rich-text">
            <div className="our-goal-is-to-provi lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText6}</span>
            </div>
            <div className="overlap-group6-1">
              <div className="margin-1"></div>
              <div className="though-the-phrase-p lato-bold-torea-bay-18px">
                <span className="lato-bold-torea-bay-18px">{spanText7}</span>
              </div>
            </div>
            <div className="margin-2"></div>
            <div className="overlap-group7-1">
              <div className="margin-3"></div>
              <div className="more-than-just-buzzwords lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText8}</span>
              </div>
            </div>
            <div className="overlap-group5-1">
              <div className="margin"></div>
              <div className="in-recent-years-tho lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText9}</span>
              </div>
            </div>
            <div className="overlap-group-14" style={{ backgroundImage: `url(${overlapGroup})` }}>
              <div className="instruction-in-whic lato-medium-mirage-20px">
                <span className="lato-medium-mirage-20px">{spanText10}</span>
              </div>
            </div>
            <div className="overlap-group2-3">
              <div className="margin-4"></div>
              <div className="such-a-broad-definit lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText11}</span>
                <span className="lato-bold-torea-bay-18px">{spanText12}</span>
              </div>
            </div>
            <div className="margin24px"></div>
            <div className="overlap-group9-1">
              <img className="image" src={image1} />
              <div className="margin-5"></div>
            </div>
            <div className="overlap-group-13">
              <div className="margin48px"></div>
              <div className="a-messy-reality lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText13}</span>
              </div>
            </div>
            <div className="overlap-group4-1">
              <div className="margin"></div>
              <div className="though-well-meaning lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">
                  Though well-meaning, this drive for specifically personalized learning has created a &#34;messy
                  reality&#34; in terms of impact and outcomes for students, educators, and educational institutions
                  themselves. On the one hand, researchers, educators, and students have noted that personalized
                  learning platforms have improved accessibility to educational opportunities. On the other, there has
                  been a loss of human-to-human connection in teaching and learning that has adversely affected the
                  expected educational outcomes for students and educators alike.
                  <br />
                  <br />
                  Moreover, the rush to personalized learning in schools and colleges (especially through platforms) has
                  largely been uncritical of the potential consequences of implementing new technologies for the human
                  actors involved in education. &#34;Who wouldn&#39;t want to personalize learning for students if it
                  would help them to succeed?&#34; might be an underlying assumption behind these good intentions. But
                  good intentions have produced two key unintended pedagogical consequences:
                  <br />
                  <br />
                  As Michael Feldstein and Phil Hill have shown, personalized learning platforms have{" "}
                </span>
                <span className="lato-medium-shadow-blue-18px">{spanText15}</span>
                <span className="lato-medium-shadow-blue-18px">{spanText16}</span>
                <span className="lato-medium-shadow-blue-18px">{spanText17}</span>
                <span className="lato-medium-shadow-blue-18px">{spanText18}</span>
                <span className="lato-bold-shadow-blue-18px">{spanText19}</span>
                <span className="lato-bold-shadow-blue-18px">{spanText20}</span>
                <span className="lato-medium-shadow-blue-18px">
                  <br />
                  Instead, we need to figure out why these attempts at personalized learning ultimately de-personalized
                  educational experience in practice. One persuasive explanation is an overemphasis on personalized
                  learning as a technological product alone rather than also as a human practice . Here again is an
                  example of why technological products alone, as Justin Reich has aptly shown in his new book,
                  can&#39;t transform education. <br />
                  <br />
                  To this chorus of wise voices, I would add our key perspectives on personalized learning from our
                  research and experience:
                  <br />
                  <br />
                  Personalizing learning makes too many assumptions about how students should learn without
                  personalizing delivery based upon students&#39; background, experience, and interests and assumes a
                  one-way process, where students are guided individually by technology at the expense of teachers.
                  <br />
                  Such an approach makes personalized learning technologically predetermined for students, thus making
                  personalization available to some and not others
                  <br />
                  By contrast, at its core, successful educational experiences are a multi-way, interactive process
                  among teachers and students
                </span>
              </div>
            </div>
            <img className="image-1" src={image2} />
            <div className="overlap-group-13">
              <div className="margin48px"></div>
              <div className="personalized-education lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText22}</span>
                <span className="lato-bold-mirage-24px">{spanText23}</span>
                <span className="lato-bold-mirage-24px">{spanText24}</span>
              </div>
            </div>
            <div className="overlap-group1-4">
              <div className="margin"></div>
              <div className="personalized-learnin lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">
                  Personalized learning makes an assumption that technology will solves the needs of an individual
                  student. Instead of seeking personalized learning alone, we should be striving for “personal
                  education” for students and educators alike. Personal education begins with the practice of addressing
                  the individual human needs of the students and educators involved. We’re not building an app first and
                  then figuring out how it can be applied to education. Before we ask how technology can help us, we
                  must consider the outcomes that the student and educator are hoping to achieve.
                  <br />
                  <br />
                  Therefore, personal education:
                  <br />
                  <br />
                  Allows for self-cultivation in one-on-one meetings between students and educators
                  <br />
                  Encourages students and educators to take their respective interests, strengths, needs, and
                  backgrounds into account in their interactions
                  <br />
                  Fosters academic and career self-realization for both students and educators
                  <br />
                  Encompasses tutoring, teaching, coaching, and mentoring, all of which enable students and educators to
                  address both short-term needs and long-term goals
                  <br />
                  Acknowledges the connection between academic and career concerns for both educators and students
                  <br />
                  <br />
                  This approach draws upon historical research and pedagogical literature on the history of education
                  from antiquity to the present and our combined 20+ years of practical teaching in secondary and higher
                  education.
                  <br />
                  <br />
                </span>
                <span className="lato-bold-mirage-24px">{spanText26}</span>
                <span className="span2">{spanText27}</span>
                <span className="lato-bold-mirage-24px">{spanText28}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="group-39720">
          <img className="line-477" src={line477} />
          <div className="group-container-9">
            <img className="mask-group-3" src={maskGroup2} />
            <div className="group-39499">
              <div className="group-39629">
                <div className="name lato-bold-mirage-20px">
                  <span className="lato-bold-mirage-20px">{spanText29}</span>
                </div>
                <div className="sub-headings-68 lato-medium-torea-bay-16px">
                  <span className="lato-medium-torea-bay-16px">{spanText30}</span>
                </div>
              </div>
              <div className="lorem-ipsum-dolor-si-1 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText31}</span>
              </div>
              <div className="group-2793-9">
                <div className="group-2787-9">
                  <img className="icon-facebook-9" src="/img/vector-16@2x.png" />
                </div>
                <div className="group-75-2">
                  <img className="vector-18" src={vector2} />
                </div>
                <div className="group-73-1">
                  <img className="vector-19" src={vector3} />
                </div>
                <div className="group-78-3">
                  <img className="icon-linkedin-9" src={vector4} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-group14">
          <div className="flex-col-10">
            <div className="latest-articles lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText32}</span>
            </div>
            <BlogV1Card1
              overlapGroup1={blogV1Card1Props.overlapGroup1}
              spanText={blogV1Card1Props.spanText}
              className={blogV1Card1Props.className}
              frame35995Props={blogV1Card1Props.frame35995Props}
              frame35996Props={blogV1Card1Props.frame35996Props}
            />
          </div>
          <BlogV1Card2
            overlapGroup3={blogV1Card2Props.overlapGroup3}
            spanText={blogV1Card2Props.spanText}
            className={blogV1Card2Props.className}
            frame35995Props={blogV1Card2Props.frame35995Props}
            frame35996Props={blogV1Card2Props.frame35996Props}
          />
          <div className="flex-col-11">
            <SecondaryBTN>{secondaryBTNProps.children}</SecondaryBTN>
            <BlogV1Card3
              overlapGroup5={blogV1Card3Props.overlapGroup5}
              spanText={blogV1Card3Props.spanText}
              className={blogV1Card3Props.className}
              frame35995Props={blogV1Card3Props.frame35995Props}
              frame35996Props={blogV1Card3Props.frame35996Props}
            />
          </div>
        </div>
        <Footer
          group39616={footerProps.group39616}
          className={footerProps.className}
          group751Props={footerProps.group751Props}
          group731Props={footerProps.group731Props}
          group752Props={footerProps.group752Props}
          group732Props={footerProps.group732Props}
        />
      </div>
    </div>
  );
}

export default BlogPost;
