import React from "react";
import Frame35995 from "../Frame35995";
import Frame35996 from "../Frame35996";
import "./BlogV1Card3.sass";

function BlogV1Card3(props) {
  const { overlapGroup5, spanText, className, frame35995Props, frame35996Props } = props;

  return (
    <div className={`blog-v1-card-3 ${className || ""}`}>
      <div className="overlap-group5" style={{ backgroundImage: `url(${overlapGroup5})` }}></div>
      <div className="group-38096-1">
        <Frame35995 className={frame35995Props.className}>{frame35995Props.children}</Frame35995>
        <div className="address-2 lato-bold-mirage-24px">
          <span className="span3rvcf lato-bold-mirage-24px">{spanText}</span>
        </div>
        <Frame35996 className={frame35996Props.className} />
      </div>
    </div>
  );
}

export default BlogV1Card3;
