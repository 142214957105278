import React from "react";
import Header from "../Header";
import Group37933 from "../Group37933";
import Group37931 from "../Group37931";
import PrimaryBTN4 from "../PrimaryBTN4";
import Footer from "../Footer";
import "./PolisContact.sass";

function PolisContact(props) {
  const {
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    rectangle4032,
    ellipse407,
    group38113,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    vector2,
    spanText9,
    rectangle15,
    overlapGroup,
    vector3,
    spanText10,
    headerProps,
    group379331Props,
    group379332Props,
    group379311Props,
    group379312Props,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="polis-contact screen">
        <div className="overlap-group4-5">
          <Header className={headerProps.className} />
          <div className="follow-us lato-bold-mirage-24px">
            <span className="lato-bold-mirage-24px">{spanText1}</span>
          </div>
          <div className="contact-form">
            <div className="group-39523">
              <div className="sub-headings-108 lato-medium-torea-bay-16px">
                <span className="lato-medium-torea-bay-16px">{spanText2}</span>
              </div>
              <div className="sub-headings-container-2">
                <div className="sub-headings-109 lato-medium-shadow-blue-18px">
                  <span className="lato-medium-shadow-blue-18px">{spanText3}</span>
                </div>
                <div className="sub-headings-110 lato-bold-mirage-38px">
                  <span className="lato-bold-mirage-38px">{spanText4}</span>
                </div>
              </div>
            </div>
            <div className="overlap-group3-5">
              <div className="rectangle-4097-1"></div>
              <img className="rectangle-4032-1" src={rectangle4032} />
              <img className="ellipse-407" src={ellipse407} />
              <div className="group-38113" style={{ backgroundImage: `url(${group38113})` }}>
                <div className="group-37882">
                  <div className="group-container-12">
                    <Group37933 spanText1={group379331Props.spanText1} spanText2={group379331Props.spanText2} />
                    <Group37933
                      spanText1={group379332Props.spanText1}
                      spanText2={group379332Props.spanText2}
                      className={group379332Props.className}
                    />
                    <div className="group-37940">
                      <div className="sub-headings-107 lato-bold-mirage-18px">
                        <span className="lato-bold-mirage-18px">{spanText5}</span>
                      </div>
                      <div className="group-3-1">
                        <div className="select-service lato-medium-shadow-blue-18px">
                          <span className="lato-medium-shadow-blue-18px">{spanText6}</span>
                        </div>
                        <img className="vector-23" src="/img/vector-51@2x.png" />
                      </div>
                    </div>
                  </div>
                  <div className="group-container-13">
                    <Group37931 spanText1={group379311Props.spanText1} spanText2={group379311Props.spanText2} />
                    <Group37931
                      spanText1={group379312Props.spanText1}
                      spanText2={group379312Props.spanText2}
                      className={group379312Props.className}
                    />
                    <div className="group-37939">
                      <div className="sub-headings-107 lato-bold-mirage-18px">
                        <span className="lato-bold-mirage-18px">{spanText7}</span>
                      </div>
                      <div className="group-3-1">
                        <div className="budget lato-medium-shadow-blue-18px">
                          <span className="lato-medium-shadow-blue-18px">{spanText8}</span>
                        </div>
                        <img className="vector-24" src={vector2} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-group1-9">
                  <div className="describe-your-project lato-medium-shadow-blue-18px">
                    <span className="lato-medium-shadow-blue-18px">{spanText9}</span>
                  </div>
                </div>
                <PrimaryBTN4 />
              </div>
            </div>
          </div>
          <img className="rectangle-15" src={rectangle15} />
          <div className="group-38121">
            <div className="group-38117">
              <div className="overlap-group-16" style={{ backgroundImage: `url(${overlapGroup})` }}>
                <img className="vector-25" src={vector3} />
              </div>
            </div>
            <div className="heading-h2-3 lato-medium-mirage-18px">
              <span className="lato-medium-mirage-18px">{spanText10}</span>
            </div>
          </div>
        </div>
        <Footer
          group39616={footerProps.group39616}
          className={footerProps.className}
          group751Props={footerProps.group751Props}
          group731Props={footerProps.group731Props}
          group752Props={footerProps.group752Props}
          group732Props={footerProps.group732Props}
        />
      </div>
    </div>
  );
}

export default PolisContact;
