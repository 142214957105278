import React from "react";
import Header from "../Header";
import Frame35995 from "../Frame35995";
import Frame35996 from "../Frame35996";
import BlogV1Card1 from "../BlogV1Card1";
import BlogV1Card4 from "../BlogV1Card4";
import BlogV1Card2 from "../BlogV1Card2";
import BlogV1Card5 from "../BlogV1Card5";
import BlogV1Card3 from "../BlogV1Card3";
import BlogV1Card6 from "../BlogV1Card6";
import CTA from "../CTA";
import Footer from "../Footer";
import "./PolisBlog.sass";

function PolisBlog(props) {
  const {
    spanText1,
    spanText2,
    maskGroup1,
    spanText3,
    spanText4,
    maskGroup2,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    spanText9,
    frame359951Props,
    frame359952Props,
    blogV1Card1Props,
    blogV1Card4Props,
    blogV1Card2Props,
    blogV1Card5Props,
    blogV1Card3Props,
    blogV1Card6Props,
    cTAProps,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="polis-blog screen">
        <Header />
        <div className="flex-row-2">
          <div className="blog-title-goes-here lato-bold-mirage-54px">
            <span className="lato-bold-mirage-54px">{spanText1}</span>
          </div>
          <div className="sub-headings-6 lato-medium-shadow-blue-18px">
            <span className="lato-medium-shadow-blue-18px">{spanText2}</span>
          </div>
        </div>
        <div className="overlap-group8">
          <div className="rectangle-4047"></div>
          <div className="featured-post-1">
            <img className="mask-group" src={maskGroup1} />
            <div className="group-38093">
              <Frame35995>{frame359951Props.children}</Frame35995>
              <div className="overlap-group-1">
                <div className="what-is-personal-education lato-bold-mirage-38px">
                  <span className="lato-bold-mirage-38px">{spanText3}</span>
                </div>
                <div className="sub-headings-7 lato-medium-shadow-blue-18px">
                  <span className="lato-medium-shadow-blue-18px">{spanText4}</span>
                </div>
              </div>
              <Frame35996 />
            </div>
          </div>
        </div>
        <div className="featured-post-1-1">
          <img className="mask-group" src={maskGroup2} />
          <div className="group-38093-1">
            <Frame35995>{frame359952Props.children}</Frame35995>
            <div className="why-we-founded-polis lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText5}</span>
            </div>
            <div className="sub-headings-8 lato-medium-shadow-blue-18px">
              <span className="lato-medium-shadow-blue-18px">{spanText6}</span>
            </div>
            <Frame35996 />
          </div>
        </div>
        <div className="overlap-group9">
          <div className="rectangle-4054"></div>
          <BlogV1Card1
            overlapGroup1={blogV1Card1Props.overlapGroup1}
            spanText={blogV1Card1Props.spanText}
            frame35995Props={blogV1Card1Props.frame35995Props}
            frame35996Props={blogV1Card1Props.frame35996Props}
          />
          <BlogV1Card4
            overlapGroup2={blogV1Card4Props.overlapGroup2}
            spanText={blogV1Card4Props.spanText}
            frame35995Props={blogV1Card4Props.frame35995Props}
            frame35996Props={blogV1Card4Props.frame35996Props}
          />
          <BlogV1Card2
            overlapGroup3={blogV1Card2Props.overlapGroup3}
            spanText={blogV1Card2Props.spanText}
            frame35995Props={blogV1Card2Props.frame35995Props}
            frame35996Props={blogV1Card2Props.frame35996Props}
          />
          <BlogV1Card5
            overlapGroup4={blogV1Card5Props.overlapGroup4}
            spanText={blogV1Card5Props.spanText}
            frame35995Props={blogV1Card5Props.frame35995Props}
            frame35996Props={blogV1Card5Props.frame35996Props}
          />
          <BlogV1Card3
            overlapGroup5={blogV1Card3Props.overlapGroup5}
            spanText={blogV1Card3Props.spanText}
            frame35995Props={blogV1Card3Props.frame35995Props}
            frame35996Props={blogV1Card3Props.frame35996Props}
          />
          <BlogV1Card6
            overlapGroup6={blogV1Card6Props.overlapGroup6}
            spanText={blogV1Card6Props.spanText}
            frame35995Props={blogV1Card6Props.frame35995Props}
            frame35996Props={blogV1Card6Props.frame35996Props}
          />
          <div className="latest-posts lato-bold-mirage-38px">
            <span className="lato-bold-mirage-38px">{spanText7}</span>
          </div>
          <div className="group-38112">
            <div className="primary-btn-4">
              <div className="button-text-4 lato-bold-torea-bay-18px">
                <span className="lato-bold-torea-bay-18px">{spanText8}</span>
              </div>
            </div>
            <div className="primary-btn-5">
              <div className="button-text-5 lato-bold-white-18px">
                <span className="lato-bold-white-18px">{spanText9}</span>
              </div>
            </div>
          </div>
          <CTA
            rectangle4032={cTAProps.rectangle4032}
            maskGroup={cTAProps.maskGroup}
            spanText1={cTAProps.spanText1}
            spanText2={cTAProps.spanText2}
            spanText3={cTAProps.spanText3}
          />
          <Footer
            group39616={footerProps.group39616}
            group751Props={footerProps.group751Props}
            group731Props={footerProps.group731Props}
            group752Props={footerProps.group752Props}
            group732Props={footerProps.group732Props}
          />
        </div>
      </div>
    </div>
  );
}

export default PolisBlog;
