import React from "react";
import Frame35995 from "../Frame35995";
import Frame35996 from "../Frame35996";
import "./BlogV1Card2.sass";

function BlogV1Card2(props) {
  const { overlapGroup3, spanText, className, frame35995Props, frame35996Props } = props;

  return (
    <div className={`blog-v1-card-2 ${className || ""}`}>
      <div className="overlap-group3" style={{ backgroundImage: `url(${overlapGroup3})` }}></div>
      <div className="group-38095">
        <Frame35995 className={frame35995Props.className}>{frame35995Props.children}</Frame35995>
        <div className="what-is-growth-hacki lato-bold-mirage-24px">
          <span className="spanpd6ey lato-bold-mirage-24px">{spanText}</span>
        </div>
        <Frame35996 className={frame35996Props.className} />
      </div>
    </div>
  );
}

export default BlogV1Card2;
