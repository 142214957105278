import React from "react";
import Header from "../Header";
import AvatarSophieMooreCircleSmall from "../AvatarSophieMooreCircleSmall";
import PrimaryBTN4 from "../PrimaryBTN4";
import SecondaryBTN from "../SecondaryBTN";
import Group39309 from "../Group39309";
import Group37853 from "../Group37853";
import PrimaryBTN2 from "../PrimaryBTN2";
import PrimaryBTN from "../PrimaryBTN";
import BlogV1Card1 from "../BlogV1Card1";
import BlogV1Card2 from "../BlogV1Card2";
import BlogV1Card3 from "../BlogV1Card3";
import Group37933 from "../Group37933";
import Group37931 from "../Group37931";
import Footer from "../Footer";
import "./PolisHome.sass";

function PolisHome(props) {
  const {
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    line4511,
    spanText5,
    spanText6,
    line4512,
    spanText7,
    spanText8,
    line4513,
    ellipse114,
    rectangle1514,
    vector15,
    vector16,
    group39549,
    avatarMikeWarrenCircleSmall,
    maskGroup1,
    spanText9,
    spanText10,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    spanText15,
    maskGroup2,
    maskGroup3,
    spanText16,
    spanText17,
    spanText18,
    spanText19,
    spanText20,
    spanText21,
    spanText22,
    maskGroup4,
    spanText23,
    spanText24,
    spanText25,
    spanText26,
    maskGroup5,
    spanText27,
    spanText28,
    spanText29,
    maskGroup6,
    spanText30,
    spanText31,
    spanText32,
    spanText33,
    spanText34,
    spanText35,
    spanText36,
    maskGroup7,
    spanText37,
    maskGroup8,
    spanText38,
    spanText39,
    spanText40,
    spanText41,
    spanText42,
    spanText43,
    spanText44,
    spanText45,
    overlapGroup3,
    group39141,
    spanText46,
    spanText47,
    rectangle4032,
    ellipse407,
    group38113,
    spanText48,
    spanText49,
    spanText50,
    spanText51,
    vector2,
    spanText52,
    headerProps,
    avatarSophieMooreCircleSmall1Props,
    avatarSophieMooreCircleSmall2Props,
    primaryBTN4Props,
    secondaryBTNProps,
    group393091Props,
    group393092Props,
    group393093Props,
    group378531Props,
    primaryBTN2Props,
    primaryBTNProps,
    blogV1Card1Props,
    blogV1Card2Props,
    blogV1Card31Props,
    group378532Props,
    blogV1Card32Props,
    group379331Props,
    group379332Props,
    group379311Props,
    group379312Props,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="polis-home screen">
        <Header className={headerProps.className} />
        <div className="overlap-group-container-2">
          <div className="overlap-group7-3">
            <div className="sub-headings-124 literata-bold-gray-suit-16px">
              <span className="literata-bold-gray-suit-16px">{spanText1}</span>
            </div>
            <div className="sub-headings-125 lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText2}</span>
            </div>
            <div className="group-39498">
              <div className="service-1">
                <div className="overlap-group-20">
                  <div className="group-38028-3"></div>
                  <div className="sub-headings-126 lato-bold-mirage-24px">
                    <span className="lato-bold-mirage-24px">{spanText3}</span>
                  </div>
                  <div className="overlap-group1-11">
                    <div className="sub-headings-127 lato-medium-shadow-blue-18px">
                      <span className="lato-medium-shadow-blue-18px">{spanText4}</span>
                    </div>
                    <img className="line-451-1" src={line4511} />
                  </div>
                </div>
              </div>
              <div className="service-2">
                <div className="group-container-14">
                  <div className="group-38028-4"></div>
                  <div className="overlap-group1-10">
                    <div className="sub-headings-119 lato-medium-shadow-blue-18px">
                      <span className="lato-medium-shadow-blue-18px">{spanText5}</span>
                    </div>
                    <div className="sub-headings-128 lato-bold-mirage-24px">
                      <span className="lato-bold-mirage-24px">{spanText6}</span>
                    </div>
                    <img className="line-451-2" src={line4512} />
                  </div>
                </div>
              </div>
              <div className="service-3">
                <div className="group-container-15">
                  <div className="group-38028-5"></div>
                  <div className="overlap-group1-10">
                    <div className="sub-headings-119 lato-medium-shadow-blue-18px">
                      <span className="lato-medium-shadow-blue-18px">{spanText7}</span>
                    </div>
                    <div className="sub-headings-129 lato-bold-mirage-24px">
                      <span className="lato-bold-mirage-24px">{spanText8}</span>
                    </div>
                    <img className="line-451-3" src={line4513} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-group-container-3">
            <div className="overlap-group8-2">
              <div className="rectangle-1512-1"></div>
              <img className="ellipse-114-2" src={ellipse114} />
              <div className="rectangle-1513-2"></div>
              <div className="rectangle-4050"></div>
              <img className="rectangle-1514-1" src={rectangle1514} />
              <div className="overlap-group1-12">
                <div className="rectangle-4049"></div>
                <div className="ellipse-429"></div>
                <div className="ellipse-430"></div>
                <div className="ellipse-431"></div>
                <div className="rectangle-4409"></div>
                <div className="group-39566">
                  <div className="vector-container">
                    <img className="vector-15-1" src={vector15} />
                    <img className="vector-16-1" src={vector16} />
                  </div>
                  <div className="group-39562">
                    <div className="ellipse-432"></div>
                    <div className="rectangle-4432"></div>
                    <div className="ellipse-433"></div>
                    <div className="rectangle-4433"></div>
                  </div>
                </div>
                <div className="group-39567">
                  <div className="group-39555">
                    <div className="ellipse-container">
                      <div className="ellipse-49"></div>
                      <div className="ellipse-50"></div>
                      <div className="ellipse-48"></div>
                    </div>
                  </div>
                  <div className="group-39559">
                    <div className="group-39552">
                      <div className="ellipse-432-1"></div>
                      <div className="rectangle-4432-1"></div>
                    </div>
                    <div className="group-39551">
                      <div className="ellipse-433-1"></div>
                      <div className="rectangle-4433-1"></div>
                    </div>
                    <div className="group-39550">
                      <div className="ellipse-434"></div>
                      <div className="rectangle-4434"></div>
                    </div>
                  </div>
                </div>
                <div className="group-39553">
                  <img className="group-39549" src={group39549} />
                  <div className="group-39554">
                    <div className="group-39552-1">
                      <div className="ellipse-432"></div>
                      <div className="rectangle-4432"></div>
                    </div>
                    <div className="group-39551-1">
                      <div className="ellipse-433-2"></div>
                      <div className="rectangle-4433"></div>
                    </div>
                    <div className="group-39550-1">
                      <div className="ellipse-434-1"></div>
                      <div className="rectangle-4434-1"></div>
                    </div>
                  </div>
                </div>
                <div className="group-39569">
                  <div className="circle-small-container">
                    <div
                      className="avatar-mike-warren-circle-small"
                      style={{ backgroundImage: `url(${avatarMikeWarrenCircleSmall})` }}
                    >
                      <img className="mask-group-13" src={maskGroup1} />
                    </div>
                    <AvatarSophieMooreCircleSmall
                      avatarSophieMooreCircleSmall={avatarSophieMooreCircleSmall1Props.avatarSophieMooreCircleSmall}
                      maskGroup={avatarSophieMooreCircleSmall1Props.maskGroup}
                    />
                    <AvatarSophieMooreCircleSmall
                      maskGroup={avatarSophieMooreCircleSmall2Props.maskGroup}
                      avatarSophieMooreCircleSmall={avatarSophieMooreCircleSmall2Props.avatarSophieMooreCircleSmall}
                      className={avatarSophieMooreCircleSmall2Props.className}
                    />
                  </div>
                  <div className="group-container-16">
                    <div className="group-4233">
                      <div className="rectangle-1283"></div>
                      <div className="rectangle-1284"></div>
                    </div>
                    <div className="group-4234">
                      <div className="rectangle-1283-1"></div>
                      <div className="rectangle-1284-1"></div>
                    </div>
                    <div className="group-4235">
                      <div className="rectangle-1283-2"></div>
                      <div className="rectangle-1284"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-group9-3">
              <div className="group-38091">
                <div className="sub-headings-130 literata-bold-gray-suit-16px">
                  <span className="literata-bold-gray-suit-16px">{spanText9}</span>
                </div>
                <div className="sub-headings-131 lato-bold-mirage-46px">
                  <span className="lato-bold-mirage-46px">{spanText10}</span>
                </div>
                <div className="sub-headings-132 lato-medium-shadow-blue-18px">
                  <span className="lato-medium-shadow-blue-18px">{spanText11}</span>
                </div>
                <div className="frame-35997"></div>
              </div>
              <div className="primary-btn-36">
                <div className="button-text-36 lato-bold-white-18px">
                  <span className="lato-bold-white-18px">{spanText12}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us">
          <div className="flex-row-25">
            <div className="group-38147">
              <div className="sub-headings-120 literata-bold-gray-suit-16px">
                <span className="literata-bold-gray-suit-16px">{spanText13}</span>
              </div>
              <div className="sub-headings-133 lato-bold-mirage-38px">
                <span className="lato-bold-mirage-38px">{spanText14}</span>
              </div>
            </div>
            <div className="sub-headings-134 lato-medium-shadow-blue-18px">
              <span className="lato-medium-shadow-blue-18px">{spanText15}</span>
            </div>
          </div>
          <div className="mask-group-container">
            <img className="mask-group-14" src={maskGroup2} />
            <img className="mask-group-15" src={maskGroup3} />
          </div>
          <div className="primary-btn-37">
            <div className="button-text-37 lato-bold-white-18px">
              <span className="lato-bold-white-18px">{spanText16}</span>
            </div>
          </div>
        </div>
        <div className="overlap-group13-1">
          <div className="rectangle-4051-2"></div>
          <div className="group-38147-1">
            <div className="sub-headings-120 lato-medium-torea-bay-16px">
              <span className="lato-medium-torea-bay-16px">{spanText17}</span>
            </div>
            <div className="sub-headings-135 lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText18}</span>
            </div>
          </div>
          <div className="frame-35998">
            <PrimaryBTN4 className={primaryBTN4Props.className} />
            <SecondaryBTN className={secondaryBTNProps.className}>{secondaryBTNProps.children}</SecondaryBTN>
          </div>
          <div className="group-39448">
            <div className="group-container-17">
              <div className="group-39307">
                <div className="group-38028-6"></div>
                <div className="group-39305">
                  <div className="sub-headings-136 lato-bold-mirage-24px">
                    <span className="lato-bold-mirage-24px">{spanText19}</span>
                  </div>
                  <div className="sub-headings-137 lato-medium-shadow-blue-18px">
                    <span className="lato-medium-shadow-blue-18px">{spanText20}</span>
                  </div>
                </div>
              </div>
              <Group39309 spanText1={group393091Props.spanText1} spanText2={group393091Props.spanText2} />
            </div>
            <div className="group-container-18">
              <Group39309
                spanText1={group393092Props.spanText1}
                spanText2={group393092Props.spanText2}
                className={group393092Props.className}
              />
              <Group39309
                spanText1={group393093Props.spanText1}
                spanText2={group393093Props.spanText2}
                className={group393093Props.className}
              />
            </div>
          </div>
          <div className="overlap-group11-1">
            <div className="rectangle-4052"></div>
            <div className="group-39600">
              <div className="sub-headings-120 lato-medium-shadow-blue-16px">
                <span className="lato-medium-shadow-blue-16px">{spanText21}</span>
              </div>
              <div className="sub-headings-138 lato-bold-white-38px">
                <span className="lato-bold-white-38px">{spanText22}</span>
              </div>
            </div>
            <div className="testimonials">
              <div className="overlap-group1-13">
                <div className="group-37846">
                  <img className="mask-group-12" src={maskGroup4} />
                  <div className="sub-headings-container-3">
                    <div className="sub-headings-121 lato-bold-mirage-18px">
                      <span className="lato-bold-mirage-18px">{spanText23}</span>
                    </div>
                    <div className="sub-headings-122 lato-medium-shadow-blue-18px">
                      <span className="lato-medium-shadow-blue-18px">{spanText24}</span>
                    </div>
                  </div>
                </div>
                <div className="sub-headings-139 lato-medium-shadow-blue-18px">
                  <span className="lato-bold-mirage-24px">{spanText25}</span>
                </div>
              </div>
            </div>
            <div className="group-37885">
              <div className="sub-headings-140 lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText26}</span>
              </div>
              <div className="group-37846-1">
                <img className="mask-group-12" src={maskGroup5} />
                <div className="sub-headings-container-4">
                  <div className="sub-headings-121 lato-bold-mirage-18px">
                    <span className="lato-bold-mirage-18px">{spanText27}</span>
                  </div>
                  <div className="sub-headings-122 lato-medium-shadow-blue-18px">
                    <span className="lato-medium-shadow-blue-18px">{spanText28}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-37886">
              <div className="sub-headings-141 lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText29}</span>
              </div>
              <div className="group-37846-2">
                <img className="mask-group-12" src={maskGroup6} />
                <div className="sub-headings-container-5">
                  <div className="sub-headings-121 lato-bold-mirage-18px">
                    <span className="lato-bold-mirage-18px">{spanText30}</span>
                  </div>
                  <div className="sub-headings-122 lato-medium-shadow-blue-18px">
                    <span className="lato-medium-shadow-blue-18px">{spanText31}</span>
                  </div>
                </div>
              </div>
            </div>
            <Group37853 className={group378531Props.className} />
            <PrimaryBTN2 className={primaryBTN2Props.className}>{primaryBTN2Props.children}</PrimaryBTN2>
            <div className="sub-headings-142 lato-medium-snuff-18px">
              <span className="lato-medium-snuff-18px">{spanText32}</span>
            </div>
          </div>
        </div>
        <div className="educators">
          <div className="group-39349-1">
            <div className="group-39343-1">
              <div className="sub-headings-120 lato-medium-torea-bay-16px">
                <span className="lato-medium-torea-bay-16px">{spanText33}</span>
              </div>
              <div className="sub-headings-143 lato-bold-mirage-38px">
                <span className="lato-bold-mirage-38px">{spanText34}</span>
              </div>
              <div className="sub-headings-144 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText35}</span>
              </div>
            </div>
            <div className="primary-btn-38">
              <div className="button-text-38 lato-bold-white-18px">
                <span className="lato-bold-white-18px">{spanText36}</span>
              </div>
            </div>
          </div>
          <div className="group-container-19">
            <img className="mask-group-16" src={maskGroup7} />
            <div className="group-39344">
              <div className="sub-headings-145 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText37}</span>
              </div>
              <div className="group-39192">
                <img className="mask-group-17" src={maskGroup8} />
                <div className="flex-col-13">
                  <div className="sub-headings-146 lato-bold-mirage-18px">
                    <span className="lato-bold-mirage-18px">{spanText38}</span>
                  </div>
                  <div className="ceo-at-marketingly-x lato-medium-shadow-blue-18px">
                    <span className="lato-medium-shadow-blue-18px">{spanText39}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="resources">
          <div className="flex-row-26">
            <div className="sub-headings-container-6">
              <div className="sub-headings-147 lato-medium-torea-bay-16px">
                <span className="lato-medium-torea-bay-16px">{spanText40}</span>
              </div>
              <div className="sub-headings-148 lato-bold-mirage-38px">
                <span className="lato-bold-mirage-38px">{spanText41}</span>
              </div>
            </div>
            <PrimaryBTN className={primaryBTNProps.className}>{primaryBTNProps.children}</PrimaryBTN>
            <div className="secondary-btn-3">
              <div className="button-text-39 lato-medium-torea-bay-18px">
                <span className="lato-medium-torea-bay-18px">{spanText42}</span>
              </div>
            </div>
          </div>
          <div className="flex-row-27">
            <BlogV1Card1
              overlapGroup1={blogV1Card1Props.overlapGroup1}
              spanText={blogV1Card1Props.spanText}
              className={blogV1Card1Props.className}
              frame35995Props={blogV1Card1Props.frame35995Props}
              frame35996Props={blogV1Card1Props.frame35996Props}
            />
            <BlogV1Card2
              overlapGroup3={blogV1Card2Props.overlapGroup3}
              spanText={blogV1Card2Props.spanText}
              className={blogV1Card2Props.className}
              frame35995Props={blogV1Card2Props.frame35995Props}
              frame35996Props={blogV1Card2Props.frame35996Props}
            />
            <div className="overlap-group5-4">
              <BlogV1Card3
                overlapGroup5={blogV1Card31Props.overlapGroup5}
                spanText={blogV1Card31Props.spanText}
                className={blogV1Card31Props.className}
                frame35995Props={blogV1Card31Props.frame35995Props}
                frame35996Props={blogV1Card31Props.frame35996Props}
              />
              <Group37853 className={group378532Props.className} />
            </div>
            <BlogV1Card3
              overlapGroup5={blogV1Card32Props.overlapGroup5}
              spanText={blogV1Card32Props.spanText}
              className={blogV1Card32Props.className}
              frame35995Props={blogV1Card32Props.frame35995Props}
              frame35996Props={blogV1Card32Props.frame35996Props}
            />
          </div>
        </div>
        <div className="contact-form-1">
          <div className="group-39523-1">
            <div className="sub-headings-120 lato-medium-torea-bay-16px">
              <span className="lato-medium-torea-bay-16px">{spanText43}</span>
            </div>
            <div className="sub-headings-container-7">
              <div className="sub-headings-149 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText44}</span>
              </div>
              <div className="sub-headings-150 lato-bold-mirage-38px">
                <span className="lato-bold-mirage-38px">{spanText45}</span>
              </div>
            </div>
            <div className="overlap-group3-6" style={{ backgroundImage: `url(${overlapGroup3})` }}>
              <img className="group-39141" src={group39141} />
              <div className="sub-headings-container-8">
                <div className="sub-headings-151 lato-bold-mirage-18px">
                  <span className="lato-bold-mirage-18px">{spanText46}</span>
                </div>
                <div className="sub-headings-152 lato-medium-shadow-blue-16px">
                  <span className="lato-medium-shadow-blue-16px">{spanText47}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-group4-6">
            <div className="rectangle-4097-2"></div>
            <img className="rectangle-4032-2" src={rectangle4032} />
            <img className="ellipse-407-1" src={ellipse407} />
            <div className="group-38113-1" style={{ backgroundImage: `url(${group38113})` }}>
              <div className="group-37882-1">
                <div className="group-container-20">
                  <Group37933 spanText1={group379331Props.spanText1} spanText2={group379331Props.spanText2} />
                  <Group37933
                    spanText1={group379332Props.spanText1}
                    spanText2={group379332Props.spanText2}
                    className={group379332Props.className}
                  />
                  <div className="group-37940-1">
                    <div className="sub-headings-123 lato-bold-mirage-18px">
                      <span className="lato-bold-mirage-18px">{spanText48}</span>
                    </div>
                    <div className="group-3-2">
                      <div className="select-service-1 lato-medium-shadow-blue-18px">
                        <span className="lato-medium-shadow-blue-18px">{spanText49}</span>
                      </div>
                      <img className="vector-26" src="/img/vector-51@2x.png" />
                    </div>
                  </div>
                </div>
                <div className="group-container-21">
                  <Group37931 spanText1={group379311Props.spanText1} spanText2={group379311Props.spanText2} />
                  <Group37931
                    spanText1={group379312Props.spanText1}
                    spanText2={group379312Props.spanText2}
                    className={group379312Props.className}
                  />
                  <div className="group-37939-1">
                    <div className="sub-headings-123 lato-bold-mirage-18px">
                      <span className="lato-bold-mirage-18px">{spanText50}</span>
                    </div>
                    <div className="group-3-2">
                      <div className="budget-1 lato-medium-shadow-blue-18px">
                        <span className="lato-medium-shadow-blue-18px">{spanText51}</span>
                      </div>
                      <img className="vector-27" src={vector2} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-group1-14">
                <div className="describe-your-project-1 lato-medium-shadow-blue-18px">
                  <span className="lato-medium-shadow-blue-18px">{spanText52}</span>
                </div>
              </div>
              <PrimaryBTN4 />
            </div>
          </div>
        </div>
        <Footer
          group39616={footerProps.group39616}
          className={footerProps.className}
          group751Props={footerProps.group751Props}
          group731Props={footerProps.group731Props}
          group752Props={footerProps.group752Props}
          group732Props={footerProps.group732Props}
        />
      </div>
    </div>
  );
}

export default PolisHome;
