import React from "react";
import Header from "../Header";
import BlogV1Card1 from "../BlogV1Card1";
import BlogV1Card2 from "../BlogV1Card2";
import BlogV1Card3 from "../BlogV1Card3";
import Footer from "../Footer";
import "./PolisTeamMember.sass";

function PolisTeamMember(props) {
  const {
    rectangle4108,
    vector21,
    maskGroup,
    spanText1,
    spanText2,
    line448,
    spanText3,
    spanText4,
    vector2,
    vector3,
    vector4,
    spanText5,
    spanText6,
    headerProps,
    blogV1Card1Props,
    blogV1Card2Props,
    blogV1Card3Props,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="polis-team-member screen">
        <div className="overlap-group4-4">
          <div className="rectangle-4452"></div>
          <div className="ellipse-114"></div>
          <div className="rectangle-3862"></div>
          <img className="rectangle-4108-1" src={rectangle4108} />
          <div className="rectangle-4051-1"></div>
          <Header className={headerProps.className} />
          <img className="vector-21" src={vector21} />
          <div className="group-39626">
            <img className="mask-group-7" src={maskGroup} />
            <div className="group-39625">
              <div className="group-39624">
                <div className="sub-headings-90 lato-bold-mirage-38px">
                  <span className="lato-bold-mirage-38px">{spanText1}</span>
                </div>
                <div className="sub-headings-91 lato-medium-torea-bay-24px">
                  <span className="lato-medium-torea-bay-24px">{spanText2}</span>
                </div>
              </div>
              <img className="line-448" src={line448} />
              <div className="group-39623">
                <div className="group-38084">
                  <div className="sub-headings-92 lato-bold-mirage-24px">
                    <span className="lato-bold-mirage-24px">{spanText3}</span>
                  </div>
                  <div className="sub-headings-93 lato-medium-shadow-blue-18px">
                    <span className="lato-medium-shadow-blue-18px">{spanText4}</span>
                  </div>
                </div>
                <div className="group-2793-10">
                  <div className="group-2787-10">
                    <img className="icon-facebook-10" src="/img/vector-38@2x.png" />
                  </div>
                  <div className="group-75-3">
                    <img className="vector-20" src={vector2} />
                  </div>
                  <div className="group-73-2">
                    <img className="vector-22" src={vector3} />
                  </div>
                  <div className="group-78-4">
                    <img className="icon-linkedin-10" src={vector4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-39558">
            <div className="articles-by-michael lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText5}</span>
            </div>
            <div className="primary-btn-29">
              <div className="button-text-31 lato-bold-white-18px">
                <span className="lato-bold-white-18px">{spanText6}</span>
              </div>
            </div>
          </div>
          <BlogV1Card1
            overlapGroup1={blogV1Card1Props.overlapGroup1}
            spanText={blogV1Card1Props.spanText}
            className={blogV1Card1Props.className}
            frame35995Props={blogV1Card1Props.frame35995Props}
            frame35996Props={blogV1Card1Props.frame35996Props}
          />
          <BlogV1Card2
            overlapGroup3={blogV1Card2Props.overlapGroup3}
            spanText={blogV1Card2Props.spanText}
            className={blogV1Card2Props.className}
            frame35995Props={blogV1Card2Props.frame35995Props}
            frame35996Props={blogV1Card2Props.frame35996Props}
          />
          <BlogV1Card3
            overlapGroup5={blogV1Card3Props.overlapGroup5}
            spanText={blogV1Card3Props.spanText}
            className={blogV1Card3Props.className}
            frame35995Props={blogV1Card3Props.frame35995Props}
            frame35996Props={blogV1Card3Props.frame35996Props}
          />
        </div>
        <Footer
          group39616={footerProps.group39616}
          className={footerProps.className}
          group751Props={footerProps.group751Props}
          group731Props={footerProps.group731Props}
          group752Props={footerProps.group752Props}
          group732Props={footerProps.group732Props}
        />
      </div>
    </div>
  );
}

export default PolisTeamMember;
