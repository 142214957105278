import React from "react";
import "./Group73.sass";

function Group73(props) {
  const { src, className } = props;

  return (
    <div className={`group-73 ${className || ""}`}>
      <img className="icon-instagram" src={src} />
    </div>
  );
}

export default Group73;
