import React from "react";
import "./Group37853.sass";

function Group37853(props) {
  const { className } = props;

  return (
    <div className={`group-37853 ${className || ""}`}>
      <img className="group-7" src="/img/group-13@2x.png" />
    </div>
  );
}

export default Group37853;
