import React from "react";
import "./PrimaryBTN2.sass";

function PrimaryBTN2(props) {
  const { children, className } = props;

  return (
    <div className={`primary-btn-27 ${className || ""}`}>
      <div className="button-text-29 lato-bold-white-18px">
        <span className="spanixgqf lato-bold-white-18px">{children}</span>
      </div>
    </div>
  );
}

export default PrimaryBTN2;
