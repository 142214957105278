import React from "react";
import Group39122 from "../Group39122";
import Header from "../Header";
import Footer from "../Footer";
import "./PolisFAQ.sass";

function PolisFAQ(props) {
  const {
    spanText1,
    spanText2,
    spanText3,
    rectangle4114,
    spanText4,
    spanText5,
    spanText6,
    rectangle4377,
    rectangle4378,
    line4581,
    spanText7,
    group391161,
    line4591,
    spanText8,
    group392421,
    line4601,
    spanText9,
    group391171,
    spanText10,
    overlapGroup1,
    line4582,
    spanText11,
    group391162,
    line4592,
    spanText12,
    group392422,
    line4602,
    spanText13,
    group391172,
    line4611,
    spanText14,
    group392461,
    line4621,
    spanText15,
    group392441,
    spanText16,
    overlapGroup2,
    line4583,
    spanText17,
    group391163,
    line4593,
    spanText18,
    group392423,
    line4603,
    spanText19,
    group391173,
    line4612,
    spanText20,
    group392462,
    line4622,
    spanText21,
    group392442,
    spanText22,
    group391221Props,
    headerProps,
    group391222Props,
    group391223Props,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="polis-faq screen">
        <div className="overlap-group5-2">
          <div className="overlap-group3-2">
            <div className="have-questions-we-have-answers lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText1}</span>
            </div>
            <div className="sub-headings-69 lato-medium-shadow-blue-18px">
              <span className="lato-medium-shadow-blue-18px">{spanText2}</span>
            </div>
            <div className="frequently-asked-questions lato-medium-torea-bay-16px">
              <span className="lato-medium-torea-bay-16px">{spanText3}</span>
            </div>
            <img className="rectangle-4114-1" src={rectangle4114} />
            <div className="rectangle-4364"></div>
            <div className="group-39242">
              <div className="ellipse-413-1"></div>
            </div>
            <div className="ellipse-420"></div>
            <div className="have-questions-we-have-answers-1 lato-bold-zircon-54px">
              <span className="lato-bold-zircon-54px">{spanText4}</span>
            </div>
            <div className="sub-headings-70 lato-medium-white-16px">
              <span className="lato-medium-white-16px">{spanText5}</span>
            </div>
            <div className="nav-link-item lato-medium-athens-gray-18px">
              <span className="lato-medium-athens-gray-18px">{spanText6}</span>
            </div>
            <div className="rectangle-4379"></div>
            <div className="rectangle-4376"></div>
            <img className="rectangle-4377" src={rectangle4377} />
            <div className="group-39241">
              <div className="ellipse-413-2"></div>
            </div>
            <img className="rectangle-4378" src={rectangle4378} />
          </div>
          <div className="overlap-group4-2">
            <div className="group-39254">
              <Group39122 spanText1={group391221Props.spanText1} />
              <img className="line-458" src={line4581} />
              <div className="flex-row-20">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText7}</span>
                </div>
                <img className="group-39" src={group391161} />
              </div>
              <img className="line-459" src={line4591} />
              <div className="flex-row-21">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText8}</span>
                </div>
                <img className="group-39" src={group392421} />
              </div>
              <img className="line-46" src={line4601} />
              <div className="flex-row-22">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText9}</span>
                </div>
                <img className="group-39" src={group391171} />
              </div>
            </div>
            <div className="general-questions lato-bold-catalina-blue-40px">
              <span className="lato-bold-catalina-blue-40px">{spanText10}</span>
            </div>
          </div>
          <Header className={headerProps.className} />
        </div>
        <div className="overlap-group-container">
          <div className="overlap-group1-5" style={{ backgroundImage: `url(${overlapGroup1})` }}>
            <div className="group-39254-1">
              <Group39122 spanText1={group391222Props.spanText1} className={group391222Props.className} />
              <img className="line-458" src={line4582} />
              <div className="flex-row-20">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText11}</span>
                </div>
                <img className="group-39" src={group391162} />
              </div>
              <img className="line-459" src={line4592} />
              <div className="flex-row-21">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText12}</span>
                </div>
                <img className="group-39" src={group392422} />
              </div>
              <img className="line-46" src={line4602} />
              <div className="flex-row-22">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText13}</span>
                </div>
                <img className="group-39" src={group391172} />
              </div>
              <img className="line-46" src={line4611} />
              <div className="flex-row-23">
                <div className="heading-h2-1 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText14}</span>
                </div>
                <img className="group-3924" src={group392461} />
              </div>
              <img className="line-46" src={line4621} />
              <div className="flex-row-24">
                <div className="heading-h2-1 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText15}</span>
                </div>
                <img className="group-3924" src={group392441} />
              </div>
            </div>
            <div className="for-educators lato-bold-catalina-blue-40px">
              <span className="lato-bold-catalina-blue-40px">{spanText16}</span>
            </div>
          </div>
          <div className="overlap-group2-4" style={{ backgroundImage: `url(${overlapGroup2})` }}>
            <div className="group-39256">
              <Group39122 spanText1={group391223Props.spanText1} className={group391223Props.className} />
              <img className="line-458" src={line4583} />
              <div className="flex-row-20">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText17}</span>
                </div>
                <img className="group-39" src={group391163} />
              </div>
              <img className="line-459" src={line4593} />
              <div className="flex-row-21">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText18}</span>
                </div>
                <img className="group-39" src={group392423} />
              </div>
              <img className="line-46" src={line4603} />
              <div className="flex-row-22">
                <div className="heading-h2 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText19}</span>
                </div>
                <img className="group-39" src={group391173} />
              </div>
              <img className="line-46" src={line4612} />
              <div className="flex-row-23">
                <div className="heading-h2-1 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText20}</span>
                </div>
                <img className="group-3924" src={group392462} />
              </div>
              <img className="line-46" src={line4622} />
              <div className="flex-row-24">
                <div className="heading-h2-1 lato-bold-mirage-22px">
                  <span className="lato-bold-mirage-22px">{spanText21}</span>
                </div>
                <img className="group-3924" src={group392442} />
              </div>
            </div>
            <div className="for-learners lato-bold-catalina-blue-40px">
              <span className="lato-bold-catalina-blue-40px">{spanText22}</span>
            </div>
          </div>
        </div>
        <Footer
          group39616={footerProps.group39616}
          className={footerProps.className}
          group751Props={footerProps.group751Props}
          group731Props={footerProps.group731Props}
          group752Props={footerProps.group752Props}
          group732Props={footerProps.group732Props}
        />
      </div>
    </div>
  );
}

export default PolisFAQ;
