import React from "react";
import Frame35995 from "../Frame35995";
import Frame35996 from "../Frame35996";
import "./BlogV1Card1.sass";

function BlogV1Card1(props) {
  const { overlapGroup1, spanText, className, frame35995Props, frame35996Props } = props;

  return (
    <div className={`blog-v1-card-1 ${className || ""}`}>
      <div className="overlap-group1-1" style={{ backgroundImage: `url(${overlapGroup1})` }}></div>
      <div className="group-38094">
        <Frame35995 className={frame35995Props.className}>{frame35995Props.children}</Frame35995>
        <div className="address lato-bold-mirage-24px">
          <span className="span2bse lato-bold-mirage-24px">{spanText}</span>
        </div>
        <Frame35996 className={frame35996Props.className} />
      </div>
    </div>
  );
}

export default BlogV1Card1;
