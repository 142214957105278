import React from "react";
import "./AvatarSophieMooreCircleSmall.sass";

function AvatarSophieMooreCircleSmall(props) {
  const { avatarSophieMooreCircleSmall, maskGroup, className } = props;

  return (
    <div
      className={`avatar-sophie-moore-circle-small ${className || ""}`}
      style={{ backgroundImage: `url(${avatarSophieMooreCircleSmall})` }}
    >
      <img className="mask-group-18" src={maskGroup} />
    </div>
  );
}

export default AvatarSophieMooreCircleSmall;
