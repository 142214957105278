import React from "react";
import "./Frame35995.sass";

function Frame35995(props) {
  const { children, className } = props;

  return (
    <div className={`frame-35995-13 ${className || ""}`}>
      <div className="sub-headings-17 lato-medium-shadow-blue-18px">
        <span className="span-10 lato-medium-shadow-blue-18px">{children}</span>
      </div>
    </div>
  );
}

export default Frame35995;
