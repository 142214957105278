import React from "react";
import Group39358 from "../Group39358";
import "./Team2.sass";

function Team2(props) {
  const { group39332, group39333, group39334, group39358Props } = props;

  return (
    <div className="team-1">
      <Group39358
        group39329={group39358Props.group39329}
        group39330={group39358Props.group39330}
        group39331={group39358Props.group39331}
      />
      <div className="group-39355">
        <div className="group-39332" style={{ backgroundImage: `url(${group39332})` }}></div>
        <div className="group-39333" style={{ backgroundImage: `url(${group39333})` }}></div>
        <div className="group-39334" style={{ backgroundImage: `url(${group39334})` }}></div>
      </div>
    </div>
  );
}

export default Team2;
