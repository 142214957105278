import React from "react";
import "./SecondaryBTN.sass";

function SecondaryBTN(props) {
  const { children, className } = props;

  return (
    <div className={`secondary-btn-1 ${className || ""}`}>
      <div className="button-text-24 lato-medium-torea-bay-18px">
        <span className="spanshpx4 lato-medium-torea-bay-18px">{children}</span>
      </div>
    </div>
  );
}

export default SecondaryBTN;
