import React from "react";
import Team2 from "../Team2";
import PrimaryBTN from "../PrimaryBTN";
import Group75 from "../Group75";
import Group73 from "../Group73";
import "./Team.sass";

function Team(props) {
  const {
    image391,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    line478,
    rectangle4108,
    ellipse412,
    spanText7,
    spanText8,
    spanText9,
    spanText10,
    line443,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    spanText15,
    spanText16,
    spanText17,
    spanText18,
    vector2,
    group39616,
    spanText19,
    spanText20,
    spanText21,
    line444,
    spanText22,
    spanText23,
    spanText24,
    spanText25,
    team2Props,
    primaryBTNProps,
    group751Props,
    group731Props,
    group752Props,
    group732Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="team screen">
        <div className="overlap-group2">
          <div className="header">
            <div className="flex-row">
              <img className="image-391" src={image391} />
              <div className="navbar lato-medium-shadow-blue-18px">
                <div className="frame-36812">
                  <div className="sub-headings lato-medium-shadow-blue-18px">
                    <span className="lato-medium-shadow-blue-18px">{spanText1}</span>
                  </div>
                </div>
                <div className="navbar-link-sub-headings">
                  <span className="lato-medium-shadow-blue-18px">{spanText2}</span>
                </div>
                <div className="navbar-link-sub-headings-1">
                  <span className="lato-medium-shadow-blue-18px">{spanText3}</span>
                </div>
                <div className="navbar-link-sub-headings-2">
                  <span className="lato-medium-shadow-blue-18px">{spanText4}</span>
                </div>
                <div className="navbar-link-sub-headings-3">
                  <span className="lato-medium-shadow-blue-18px">{spanText5}</span>
                </div>
                <div className="primary-btn">
                  <div className="button-text lato-bold-mirage-16px">
                    <span className="lato-bold-mirage-16px">{spanText6}</span>
                  </div>
                </div>
              </div>
            </div>
            <img className="line-478" src={line478} />
          </div>
          <div className="overlap-group1">
            <div className="e-container">
              <img className="rectangle-4108" src={rectangle4108} />
              <img className="ellipse-412" src={ellipse412} />
            </div>
            <div className="flex-col">
              <h1 className="title lato-bold-white-54px">
                <span className="lato-bold-white-54px">{spanText7}</span>
              </h1>
              <div className="sub-headings-3 lato-medium-athens-gray-18px">
                <span className="lato-medium-athens-gray-18px">{spanText8}</span>
              </div>
            </div>
            <div className="e-container-1">
              <div className="ellipse-413"></div>
              <div className="rectangle-4114"></div>
              <div className="ellipse-419"></div>
            </div>
          </div>
          <Team2
            group39332={team2Props.group39332}
            group39333={team2Props.group39333}
            group39334={team2Props.group39334}
            group39358Props={team2Props.group39358Props}
          />
        </div>
        <div className="were-looking-to-add lato-bold-mirage-24px">
          <span className="lato-bold-mirage-24px">{spanText9}</span>
        </div>
        <div className="lorem-ipsum-dolor-si lato-medium-shadow-blue-18px">
          <span className="lato-medium-shadow-blue-18px">{spanText10}</span>
        </div>
        <PrimaryBTN>{primaryBTNProps.children}</PrimaryBTN>
        <div className="footer">
          <img className="line-443" src={line443} />
          <div className="flex-row-1">
            <div className="group-container">
              <div className="group-39615">
                <div className="flex-col-1">
                  <div className="sub-headings-4 lato-bold-torea-bay-18px">
                    <span className="lato-bold-torea-bay-18px">{spanText11}</span>
                  </div>
                  <div className="group-39614 lato-medium-shadow-blue-18px">
                    <div className="sub-headings-1">
                      <span className="lato-medium-shadow-blue-18px">{spanText12}</span>
                    </div>
                    <div className="sub-headings-2">
                      <span className="lato-medium-shadow-blue-18px">{spanText13}</span>
                    </div>
                    <div className="sub-headings-2">
                      <span className="lato-medium-shadow-blue-18px">{spanText14}</span>
                    </div>
                    <div className="sub-headings-5">
                      <span className="lato-medium-shadow-blue-18px">{spanText15}</span>
                    </div>
                  </div>
                </div>
                <div className="group-39613 lato-medium-shadow-blue-18px">
                  <div className="sub-headings-1">
                    <span className="lato-medium-shadow-blue-18px">{spanText16}</span>
                  </div>
                  <div className="sub-headings-2">
                    <span className="lato-medium-shadow-blue-18px">{spanText17}</span>
                  </div>
                  <div className="sub-headings-2">
                    <span className="lato-medium-shadow-blue-18px">{spanText18}</span>
                  </div>
                </div>
              </div>
              <div className="group-2793">
                <div className="group-2787">
                  <img className="icon-facebook" src="/img/vector-6@2x.png" />
                </div>
                <Group75 src={group751Props.src} />
                <Group73 src={group731Props.src} />
                <div className="group-78">
                  <img className="icon-linkedin" src={vector2} />
                </div>
                <Group75 src={group752Props.src} className={group752Props.className} />
                <Group73 src={group732Props.src} className={group732Props.className} />
              </div>
            </div>
            <div className="group-39616" style={{ backgroundImage: `url(${group39616})` }}>
              <div className="subscribe-to-our-newsletter lato-bold-white-24px">
                <span className="lato-bold-white-24px">{spanText19}</span>
              </div>
              <div className="overlap-group">
                <div className="sub-headings lato-medium-shadow-blue-18px">
                  <span className="lato-medium-shadow-blue-18px">{spanText20}</span>
                </div>
              </div>
              <div className="primary-btn-1">
                <div className="button-text-1 lato-bold-torea-bay-18px">
                  <span className="lato-bold-torea-bay-18px">{spanText21}</span>
                </div>
              </div>
            </div>
          </div>
          <img className="line-444" src={line444} />
          <div className="copyright-marketin lato-medium-shadow-blue-18px">
            <span className="lato-medium-shadow-blue-18px">{spanText22}</span>
            <span className="lato-medium-torea-bay-18px">{spanText23}</span>
            <span className="lato-medium-shadow-blue-18px">{spanText24}</span>
            <span className="lato-medium-torea-bay-18px">{spanText25}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
