import React from "react";
import PrimaryBTN2 from "../PrimaryBTN2";
import Header from "../Header";
import Group37853 from "../Group37853";
import Footer from "../Footer";
import "./PolisHowItWorks.sass";

function PolisHowItWorks(props) {
  const {
    spanText1,
    spanText2,
    spanText3,
    group1,
    group2,
    group3,
    spanText4,
    spanText5,
    spanText6,
    rectangle1514,
    maskGroup1,
    navIndicator,
    spanText7,
    spanText8,
    group38126,
    maskGroup2,
    spanText9,
    spanText10,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    maskGroup3,
    rectangle4051,
    overlapGroup,
    spanText15,
    spanText16,
    line4511,
    overlapGroup1,
    spanText17,
    spanText18,
    line4512,
    overlapGroup2,
    spanText19,
    spanText20,
    line4513,
    overlapGroup3,
    spanText21,
    spanText22,
    line4514,
    spanText23,
    ebook2,
    spanText24,
    spanText25,
    spanText26,
    spanText27,
    primaryBTN2Props,
    headerProps,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="polis-how-it-works screen">
        <div className="overlap-group9-2">
          <div className="hero-content">
            <div className="group-37862">
              <div className="sub-headings-72 literata-bold-gray-suit-16px">
                <span className="literata-bold-gray-suit-16px">{spanText1}</span>
              </div>
              <div className="sub-headings-73 lato-bold-mirage-54px">
                <span className="lato-bold-mirage-54px">{spanText2}</span>
              </div>
              <div className="sub-headings-74 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText3}</span>
              </div>
              <div className="group-37859">
                <div className="group-container-10">
                  <img className="group-3" src={group1} />
                  <img className="group-4" src={group2} />
                  <img className="group-5" src={group3} />
                </div>
                <div className="sub-headings-container lato-bold-mirage-18px">
                  <div className="sub-headings-75">
                    <span className="lato-bold-mirage-18px">{spanText4}</span>
                  </div>
                  <div className="sub-headings-76">
                    <span className="lato-bold-mirage-18px">{spanText5}</span>
                  </div>
                  <div className="sub-headings-77">
                    <span className="lato-bold-mirage-18px">{spanText6}</span>
                  </div>
                </div>
              </div>
              <PrimaryBTN2>{primaryBTN2Props.children}</PrimaryBTN2>
            </div>
            <div className="overlap-group8-1">
              <div className="rectangle-1534"></div>
              <div className="rectangle-1513"></div>
              <div className="rectangle-1512"></div>
              <div className="ellipse-119"></div>
              <img className="rectangle-1514" src={rectangle1514} />
              <img className="mask-group-4" src={maskGroup1} />
            </div>
          </div>
          <Header className={headerProps.className} />
          <img className="nav-indicator" src={navIndicator} />
        </div>
        <div className="using-polis">
          <div className="sub-headings-78 literata-bold-gray-suit-16px">
            <span className="literata-bold-gray-suit-16px">{spanText7}</span>
          </div>
          <div className="sub-headings-79 lato-bold-mirage-38px">
            <span className="lato-bold-mirage-38px">{spanText8}</span>
          </div>
          <div className="group-container-11">
            <div className="overlap-group7-2">
              <img className="group-38126" src={group38126} />
              <div className="rectangle-4425"></div>
              <img className="mask-group-5" src={maskGroup2} />
            </div>
            <div className="group-39364">
              <div className="sub-headings-container-1">
                <div className="sub-headings-80 lato-bold-mirage-38px">
                  <span className="lato-bold-mirage-38px">{spanText9}</span>
                </div>
                <div className="sub-headings-81 lato-medium-shadow-blue-18px">
                  <span className="lato-medium-shadow-blue-18px">{spanText10}</span>
                </div>
              </div>
              <div className="primary-btn-23">
                <div className="button-text-25 lato-bold-torea-bay-18px">
                  <span className="lato-bold-torea-bay-18px">{spanText11}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-group-container-1">
            <div className="overlap-group1-6">
              <div className="primary-btn-24">
                <div className="button-text-26 lato-bold-torea-bay-18px">
                  <span className="lato-bold-torea-bay-18px">{spanText12}</span>
                </div>
              </div>
              <div className="group-38016">
                <div className="sub-headings-82 lato-bold-mirage-38px">
                  <span className="lato-bold-mirage-38px">{spanText13}</span>
                </div>
                <div className="sub-headings-83 lato-medium-shadow-blue-18px">
                  <span className="lato-medium-shadow-blue-18px">{spanText14}</span>
                </div>
              </div>
            </div>
            <div className="overlap-group6-2">
              <div className="ellipse-110"></div>
              <img className="mask-group-6" src={maskGroup3} />
            </div>
          </div>
        </div>
        <div className="overlap-group10">
          <div className="overlap-group4-3">
            <img className="rectangle-4051" src={rectangle4051} />
            <div className="overlap-group-15" style={{ backgroundImage: `url(${overlapGroup})` }}>
              <div className="group-38028"></div>
              <div className="sub-headings-84 lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText15}</span>
              </div>
              <div className="sub-headings-71 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText16}</span>
              </div>
              <img className="line-451" src={line4511} />
            </div>
            <div className="overlap-group1-7" style={{ backgroundImage: `url(${overlapGroup1})` }}>
              <div className="group-38028-1"></div>
              <div className="sub-headings-85 lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText17}</span>
              </div>
              <div className="sub-headings-71 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText18}</span>
              </div>
              <img className="line-451" src={line4512} />
            </div>
            <div className="overlap-group2-5" style={{ backgroundImage: `url(${overlapGroup2})` }}>
              <div className="group-38028-2"></div>
              <div className="sub-headings-86 lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText19}</span>
              </div>
              <div className="sub-headings-71 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText20}</span>
              </div>
              <img className="line-451" src={line4513} />
            </div>
            <div className="overlap-group3-3" style={{ backgroundImage: `url(${overlapGroup3})` }}>
              <div className="group-38028"></div>
              <div className="team-work lato-bold-mirage-24px">
                <span className="lato-bold-mirage-24px">{spanText21}</span>
              </div>
              <div className="sub-headings-71 lato-medium-shadow-blue-18px">
                <span className="lato-medium-shadow-blue-18px">{spanText22}</span>
              </div>
              <img className="line-451" src={line4514} />
            </div>
            <div className="sub-headings-87 lato-bold-mirage-38px">
              <span className="lato-bold-mirage-38px">{spanText23}</span>
            </div>
            <Group37853 />
          </div>
          <div className="overlap-group5-3">
            <div className="rectangle-3859"></div>
            <div className="ellipse-118"></div>
            <div className="rectangle-3895"></div>
            <div className="e-book-2" style={{ backgroundImage: `url(${ebook2})` }}></div>
            <div className="group-39599">
              <div className="sub-headings-88 lato-bold-white-38px">
                <span className="lato-bold-white-38px">{spanText24}</span>
              </div>
              <div className="sub-headings-89 lato-medium-athens-gray-18px">
                <span className="lato-medium-athens-gray-18px">{spanText25}</span>
              </div>
              <div className="primary-btn-25">
                <div className="button-text-27 lato-bold-torea-bay-18px">
                  <span className="lato-bold-torea-bay-18px">{spanText26}</span>
                </div>
              </div>
              <div className="primary-btn-26">
                <div className="button-text-28 lato-bold-white-18px">
                  <span className="lato-bold-white-18px">{spanText27}</span>
                </div>
              </div>
            </div>
          </div>
          <Footer
            group39616={footerProps.group39616}
            className={footerProps.className}
            group751Props={footerProps.group751Props}
            group731Props={footerProps.group731Props}
            group752Props={footerProps.group752Props}
            group732Props={footerProps.group732Props}
          />
        </div>
      </div>
    </div>
  );
}

export default PolisHowItWorks;
