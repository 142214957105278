import React from "react";
import "./PrimaryBTN3.sass";

function PrimaryBTN3(props) {
  const { className } = props;

  return (
    <div className={`primary-btn-31 ${className || ""}`}>
      <div className="button-text-33 lato-bold-white-18px">
        <span className="spanj lato-bold-white-18px">Join our team</span>
      </div>
    </div>
  );
}

export default PrimaryBTN3;
