import React from "react";
import "./Frame35996.sass";

function Frame35996(props) {
  const { className } = props;

  return (
    <div className={`frame-35996-13 ${className || ""}`}>
      <div className="sub-headings-21 lato-medium-torea-bay-18px">
        <span className="span-14 lato-medium-torea-bay-18px">Read more</span>
      </div>
      <img className="group" src="/img/group-1@2x.png" />
    </div>
  );
}

export default Frame35996;
