import React from "react";
import Group75 from "../Group75";
import Group73 from "../Group73";
import "./Footer.sass";

function Footer(props) {
  const { group39616, className, group751Props, group731Props, group752Props, group732Props } = props;

  return (
    <div className={`footer-1 ${className || ""}`}>
      <img className="line-443-1" src="/img/line-443-1@2x.png" />
      <div className="flex-row-11">
        <div className="group-container-1">
          <div className="group-39615-1">
            <div className="flex-col-2">
              <div className="sub-headings-42 lato-bold-torea-bay-18px">
                <span className="span-20 lato-bold-torea-bay-18px">Pages</span>
              </div>
              <div className="group-39614-1 lato-medium-shadow-blue-18px">
                <div className="sub-headings-26">
                  <span className="span-20 lato-medium-shadow-blue-18px">Home</span>
                </div>
                <div className="sub-headings-27">
                  <span className="span-20 lato-medium-shadow-blue-18px">About</span>
                </div>
                <div className="sub-headings-27">
                  <span className="span-20 lato-medium-shadow-blue-18px">How It Works</span>
                </div>
                <div className="sub-headings-43">
                  <span className="span-20 lato-medium-shadow-blue-18px">Educators</span>
                </div>
              </div>
            </div>
            <div className="group-39613-1 lato-medium-shadow-blue-18px">
              <div className="sub-headings-26">
                <span className="span-20 lato-medium-shadow-blue-18px">FAQ</span>
              </div>
              <div className="sub-headings-27">
                <span className="span-20 lato-medium-shadow-blue-18px">Blog</span>
              </div>
              <div className="sub-headings-27">
                <span className="span-20 lato-medium-shadow-blue-18px">Get in Touch</span>
              </div>
            </div>
          </div>
          <div className="group-2793-1">
            <div className="group-2787-1">
              <img className="icon-facebook-1" src="/img/vector-6@2x.png" />
            </div>
            <Group75 src={group751Props.src} />
            <Group73 src={group731Props.src} />
            <div className="group-78-1">
              <img className="icon-linkedin-1" src="/img/vector-9@2x.png" />
            </div>
            <Group75 src={group752Props.src} className={group752Props.className} />
            <Group73 src={group732Props.src} className={group732Props.className} />
          </div>
        </div>
        <div className="group-39616-1" style={{ backgroundImage: `url(${group39616})` }}>
          <div className="subscribe-to-our-newsletter-2 lato-bold-white-24px">
            <span className="span-20 lato-bold-white-24px">Subscribe to our newsletter</span>
          </div>
          <div className="overlap-group-5">
            <div className="sub-headings-44 lato-medium-shadow-blue-18px">
              <span className="span-20 lato-medium-shadow-blue-18px">Full name</span>
            </div>
          </div>
          <div className="primary-btn-15">
            <div className="button-text-15 lato-bold-torea-bay-18px">
              <span className="span-20 lato-bold-torea-bay-18px">Subscribe</span>
            </div>
          </div>
        </div>
      </div>
      <img className="line-444-1" src="/img/line-444-1@2x.png" />
      <div className="copyright-marketin-1 lato-medium-shadow-blue-18px">
        <span className="span-20 lato-medium-shadow-blue-18px">Copyright © Polis, LLC. All Rights Reserved</span>
      </div>
    </div>
  );
}

export default Footer;
