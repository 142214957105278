import React from "react";
import "./PrimaryBTN4.sass";

function PrimaryBTN4(props) {
  const { className } = props;

  return (
    <div className={`primary-btn-33 ${className || ""}`}>
      <div className="button-text-35 lato-bold-white-18px">
        <span className="spanxyytz lato-bold-white-18px">Get in touch</span>
      </div>
    </div>
  );
}

export default PrimaryBTN4;
